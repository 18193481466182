import { Injectable,Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DataService {



  // public loginURL = "http://localhost:8081/";
  // public goUrl = "http://localhost:9090/";
  // public sitekey = "6Lfb1r8ZAAAAAL6e1t2fUo0sXfinYpW4lHyFz9vo"
  

    
     public loginURL ="https://optima.cloudwindmill.io/iam/";
     public goUrl = "https://optima.cloudwindmill.io/optima/";
    public sitekey = "6LfnDBMaAAAAAPnFjcSD4DeuAFUV2NmKO-p52Km8"
    


  totalProvider:any;
  providerTitle: any;
  totalVM: any;
  runningVM: any;
  stoppedVM: any;
  suspendedVM: any;
  terminatedVM: any;
  activeMenu: any;
  activeSubMenu: any;
  activeSubNavbarMenu: any
  vmCost: any;
  scheduledVm: any;
  currentNavPath: any;
  teamArr: any = [];
  defaultSchedule: any = [];
  providersList: any = [];
  selectedGroupId: any
  selectedTeamName: any
  showSpinner: boolean = false
  comingSoon: any
  public NAME:any
  public MOBILE:any
  public USERID:any
  public Organization:any

  constructor(private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    public route: Router) { }

  public headers(headers: any = {}) {
    let token = localStorage.getItem('token');
    if (headers) {
      return new HttpHeaders(headers);
    } else {
      if (token == null) {
        headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      } else {
        headers = new HttpHeaders({
          // 'X-Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        });
      }
      return headers;
    }
  };

  login(portal, userid, password) {
    let payload: any = {}
    payload = { portal: portal, user_id: userid, password: password }
    this.http.post<any>(this.loginURL + 'login/a61215e4ca1df7b4cfd72dab7ec4a2fd', payload).subscribe(resp => {
      if (resp.MAIN.RESULT.RESPONSE.token != '') {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('optimatoken', resp.MAIN.RESULT.RESPONSE.token);
        setTimeout(() => {
          this.document.location.href = resp.MAIN.RESULT.RESPONSE.redirect_url
        }, 1000);
      }
    })
  }

  //post method
  doPost(req, url) {
    return new Promise((resolve, reject) => {
      this.http.post(this.loginURL + url, JSON.stringify(req), { headers: this.headers() })
        .subscribe(res => {
          resolve(res)
        }, (err) => {
          reject(err);
        });
    });
  }

  //Get method
  doGet(url) {
    return new Promise((resolve, reject) => {
      this.http.get(this.loginURL + url, { headers: this.headers() })
        .subscribe(res => {
          resolve(res)
        }, (err) => {
          reject(err);
        });
    });
  }

  public cloneDeep(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  public fnToDate(date) {
    let newDate: any = new Date(date);
    let a = newDate != 'Invalid Date' ? newDate : null;
    return a;
  }

  public fnDateToString(date) {
    if (date == "0000-00-00 00:00:00")
      return null;
    let newDate: any = new Date(date).toISOString();
    return newDate;
  }

  public fnDateToString2(date) {
    if (date == "0000-00-00 00:00:00")
      return null;
    let newDate: any = new Date(date);
    return newDate;
  }

  public fnGetKeys(obj: any = {}) {
    return Object.keys(obj);
  }

  public capitalize(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  // filter
  public filter(items: any[], field: string, value: string): any[] {
    if (!items) return [];
    if (!value || value.length == 0) return items;
    return items.filter(it =>
      it[field].toLowerCase().indexOf(value.toLowerCase()) != -1);
  }

  // Storing temporary data for recommendation/Utilizaiton score data
  utdata: any = []


  // Sizing related data stored
  resSizingOpt: any = {}

  // public getUser()
  // {
  //   this.http.post(this.url,null).subscribe(resp=>{
  //   })
  // }

  fnChangeSubNavbar(route: any) {
    this.activeSubNavbarMenu = route
    this.route.navigate(['resources/resources-view/view/' + route]);
    //this.route.navigate(['resources/resources-view/overview/' + route]);
  }

}

export class DataTablesResponse {
  data: any;
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

export function fadeIn() {
  return [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('400ms ease-in', style({ opacity: 1 }))
    ])
  ];
}