<div class="below-navbar">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h3 class="below-nav-heading">
          {{data.comingSoon}}
        </h3>
      </div>
    </div>
  </div>
</div>

<section class="container mt-3">
  <div class="box position-relative">
    <div class="position-absolute align-text text-center">
      <h3 class="mb-5">
        Stay Tuned <br> We Will Launching Soon!
      </h3>
      <button class="btn btn-default mr-2" (click)="fnNavigate('providers')">
          <i class="fa fa-cloud mr-2"></i> Providers
      </button>
      <button class="btn btn-default mr-2" (click)="fnNavigate('resources/resources-view')">
        <i class="fa fa-tasks mr-2"></i> Resources
      </button>
    </div>
  </div>
</section>
