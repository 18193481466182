import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IamServiceService {
  groups:any = []
  constructor() { }
  selectedGroupID: any = 0;
  refreshGroupList = new BehaviorSubject<boolean>(true);

  public refreshGroup(value) {
    this.refreshGroupList.next(value);
  }

  public getRefreshValue() {
    return this.refreshGroupList;
  }

}
