import { Component, OnInit } from '@angular/core';
import { DataService, DataTablesResponse } from '../data.service';
import { IamServiceService } from '../iam/iam-service.service';
import { GlobalService } from '../stores/global.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  constructor(
    public data: DataService,
    public iamService: IamServiceService,
    public globalStore: GlobalService,
    private http: HttpClient,
    private route: Router
  ) { }

  dtOptions: DataTables.Settings = {};
  public start = 0;
  public limit = 10;
  public loading: boolean = false;
  public groups: any = [];
  public grlen = 0;
  public alertDate: Date;
  public loaders: any = { role_id: 0, activateLoader: false, editLoader: false };
  public deleteUserOptions = [{ id: 1, title: 'Suspend' }, { id: 2, title: 'Terminate' }];
  public addEditGroup: any = {};
  public selGroup: any = {};

  fnGetGroups(): void {
    let payload: any = {};
    payload.start = this.start;
    payload.length = this.limit;
    payload.session_id = this.globalStore.SESSION_ID;
    this.http
      .post<DataTablesResponse>(
        this.data.loginURL + 'getGroups/details',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        this.groups = resp.data;
        this.grlen = this.groups.length;
        this.iamService.groups = resp.data;
        this.loading = false;
      });
  }


  ngOnInit() {
    this.fnGetGroups()
  }

}
