import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(public data: DataService, private router: Router, private http: HttpClient) { }


  private compCost = new BehaviorSubject(true);
  private compSource = new BehaviorSubject(true);
  private sendData = new BehaviorSubject(true);
  private sendId = new BehaviorSubject(true);
  public resourceId = new BehaviorSubject(true);
  public refreshResourceList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);



  resourceArray: any = [];
  totalResCount: any
  pendingResCount: any
  runningResCount: any
  stoppedResCount: any
  terminatedResCount: any
  suspendedResCount: any
  totalCost: any
  totalScheduled: any
  totalScore: any
  showRequestButton: boolean = true
  resourceApiPayload: any
  payload: any
  resourceFilterPayload: any
  teamArr: any = []
  teamSelResId: any

  instanceInfoTemp: any = {}; // this holds the value for instance

  currentCost = this.compCost.asObservable();
  currentComp = this.compSource.asObservable();
  currentData = this.sendData.asObservable();
  currentId = this.sendId.asObservable();
  resId = this.resourceId.asObservable();

  public refreshList(value) {
    this.refreshResourceList.next(value);
  }

  public getRefreshValue() {
    return this.refreshResourceList;
  }

  respArr: any = [];
  public apiUrl = "http://54.201.252.94:8082/"
  instanceId: any
  res_id: any;
  instance_id: any;
  currentPage: any = ""
  currentResIndex = 0;
  prodId: any;
  groups: any = [];
  users: any = [];
  resourceArrayLength: any;
  currentResource: any;
  usersArr: any = [];
  startPoint: any = 0
  limitPoint: any
  changeLimitPoint: any = 10
  currentNumber: any = 0
  startingCount: any = 0
  pageNumber: any = 0
  currentPageNumber: any = 1

  changeComp(message: any) {
    this.compSource.next(message);
  }

  loadInfo(data: any) {
    this.sendData.next(data);
  }

  loadId(data: any) {
    this.sendId.next(data);
    // this.instance_id = data.InstanceID;
  }

  fnLoadOneResourceInfo(id: any, location: any) {
    this.res_id = id;
    this.fnLoadResourceId(id);

    let payload: any = {};
    payload.id = id;
    payload.location = location;
    // this.loadInfo(payload)
    // })
  }

  private subject = new Subject<any>();
  cloudRate: any

  resource: any = {}
  cloudType: any
  aws: boolean = false
  azure: boolean = false
  gcp: boolean = false
  dataLoaded: boolean = false

  fnLoadResourceId(id: any): any {
    this.res_id = id;
    this.aws = false
    this.azure = false
    this.gcp = false
    // this.dataLoaded = false
    this.http.get<ResponseDataTable>(this.data.goUrl + 'getResource', { params: { "res_id": id } }).subscribe(resp => {
      // this.loadId(resp.Resource);
      // this.subject.next(resp.Resource);
      this.resource = resp.Resource
      this.instance_id = resp.Resource.InstanceID
      this.cloudRate = resp.Resource.CloudRate.Cost
      if (resp.Resource.CloudType == 'aws' || resp.Resource.CloudType == 'awssimulator')
        this.aws = true
      if (resp.Resource.CloudType == 'azure' || resp.Resource.CloudType == 'azuresimulator')
        this.azure = true
      if (resp.Resource.CloudType == 'gcp' || resp.Resource.CloudType == 'gcpsimulator')
        this.gcp = true
      this.dataLoaded = true
      return this.resource
    })
  }

  fnGetInstanceInfo() {
    return this.subject.asObservable();
  }

  fnLoadInstaneType(data: any) {

    this.changeComp(data)
    // const url = `${this.data.goUrl + 'resources'}?user_id=1234`;
    // let payload: any = {};
    // payload.user_id = '1234';
    // this.http.post(url, payload).subscribe(resp => {
    // })
  }

  sendMessage() {
    // const url = `$(this.apiUrl + 'getProduct/instance')?regionCode=ap-south-1&instanceType=t2.large`;
    // const url = `${this.apiUrl + 'get_product/instance'}?regionCode=ap-south-1&instanceType=t2.large`;
    // const url = `${this.data.goUrl + 'resources'}?user_id=1234`;
    // this.http.get(url).subscribe(message => {
    // this.respArr = message;
    //  this.compCost.next(this.respArr);
    //   })
  }

  fnNavigate(rout: any, id: any = "") {
    //this.res_id=id;
    this.router.navigate[rout];
  }

  fnGetGroups(obj: any): void {
    let payload: any = {};
    this.http
      .post<ResponseDataTable>(
        this.data.loginURL + 'getGroups/details',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        this.groups = resp.data;
        obj = resp.data;
      });
  }

  fnGetUsers(obj: any): void {
    let payload: any = {};
    this.http
      .post<ResponseDataTable>(
        this.data.loginURL + 'users',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        this.users = resp.data;
        obj = resp.data;
      });
  }

  fnResourceId() {
    this.resourceId.next(this.res_id)
  }

  initialView: boolean = false
  showInitialView: boolean = false
  timeLeft: number = 10;
  interval: any;
  subscribeTimer: any;
  message: any
  destroyTimer: boolean = true;

  fnResourceStats() {
    let payload: any = {}
    if (this.data.providerTitle != 'All')
      payload.providerid = this.prodId
    else
      this.prodId = ''
    this.http.post<any>(this.data.goUrl + 'resourceStats', payload).subscribe(resp => {
      if (resp.stats.Cost != null) {
        this.initialView = false
        this.showInitialView = false;
        // this.loadId(resp.Resource);
        // this.overviewService.resourcesArray = resp.Resource;
        this.totalResCount = resp.stats.TotalCount
        this.runningResCount = resp.stats.StatusCount.Running
        this.stoppedResCount = resp.stats.StatusCount.Stopped
        this.suspendedResCount = resp.stats.StatusCount.Suspended
        this.terminatedResCount = resp.stats.StatusCount.Terminated
        this.totalCost = resp.stats.Cost.Cost
        this.totalScheduled = resp.stats.ScheduleCount.Active
        this.totalScore = resp.stats.Score.Avg
        // if (this.overviewService.resourcesArray.length != 0) {
        // setTimeout(() => {
        this.dataLoaded = true;
        this.pageNumber = Math.ceil(this.totalResCount / this.changeLimitPoint)
        // }, 1500)
        // }
      } else {
        this.showInitialView = true;
        // this.route.navigate(['resources/initial'])
        // this.fnInitialView()
        this.interval = setInterval(() => {
          if (this.timeLeft > 0) {
            this.timeLeft--;
          }
        }, 1000)
        setTimeout(() => {
          clearInterval(this.interval)
          if (this.destroyTimer == true) {
            this.router.navigate(['select-provider'])
          }
        }, 10700)
      }
      console.log(this.totalResCount);
    })
  }

  fnTruncateInstanceID(id: any) {
    let s = id;
    let trunc: any;
    if (s) {
      trunc = s.substr(s.lastIndexOf('/') + 1);
      return trunc;
    }
    return s;
  }

  getReportDropDownList(tenantId?: string) {
    let params = new HttpParams();

    if (tenantId) {
      params = params.set('tenantid', tenantId);
    }
    return this.http.get(this.data.goUrl + 'reportsInfo', { params }).pipe(
      map(result => {
        return result;
      })
    )
  }

  getReport(filterBody: {provider: string, week: string}, typeOfReport: string) {
    return this.http.post(this.data.goUrl + typeOfReport, filterBody).pipe(
      map(result => {
        return result;
      })
    )
  }

  uploadReport(uploadBody) {
    return this.http.post(this.data.goUrl + 'reportsEmail', uploadBody).pipe(
      map(result => {
        return result;
      })
    )
  }

  fetchStorageStats() {
    return this.http.post(this.data.goUrl + 'storagesStats', {}).pipe(
      map(result => {
        return result;
      })
    )
  }
  
  fetchProviderData() {
    return this.http.post(this.data.goUrl + 'providers', {}).pipe(
      map(result => {
        return result;
      })
    )
  }

  fetchStorageData(limit, start, provideID?: string) {
    let body: any = {};
    if (provideID) {
      body.filter = {}
      body.filter.providerid = provideID;
    }
    body.pagination = {
       start,
       limit
    }
    return this.http.post(this.data.goUrl + 'storages', { body }).pipe(
      map(result => {
        return result;
      })
    )
  }

  importStorage(providerID?: string[]) {
    const body = {
      prov_id: providerID
    }
    return this.http.post(this.data.goUrl + 'importStorage', body).pipe(
      map(result => {
        return result;
      })
    )
  }

  fetchTenantsList() {
    const body = {
      limit: 10,
      offset: 0
    }
    return this.http.post(this.data.loginURL + 'getTenants', body).pipe(
      map(result => {
        return result;
      })
    )
  }

  refreshStorage(providerId: string) {

    const params = new HttpParams()
      .set('id', providerId);

    return this.http.get(this.data.goUrl + 'refreshStoragesByProvider', { params }).pipe(
      map(response => {
        return response;
      })
    )
  }

  getRDSStats() {
    const body = {
      "limit": 10,
      "offset": 0
    }
    return this.http.post(this.data.goUrl + 'rdsStats', body).pipe(
      map(response => {
        return response;
      })
    )
  }

  importRDSData(providerID) {
    const body = {
      prov_id: providerID
    }
    return this.http.post(this.data.goUrl + 'importRds', body).pipe(
      map(result => {
        return result;
      })
    )
  }

  fetchRDS(start,limit) {
    let body: any = {};
    body.pagination = {
       start,
       limit
    }
    return this.http.post(this.data.goUrl + 'rds', body).pipe(
      map(response => {
        return response;
      })
    )
  }

  refreshRdsData(providerId) {
    const params = new HttpParams().set('id', providerId);

    return this.http.get(this.data.goUrl + 'refreshRdsByProvider', {params}).pipe(
      map(response => {
        return response;
      })
    )
  }

  rdsUtilizationReport(res_id: string, metric: string) {
    const body = {
      res_id,
      metric,
      type: "RDSweekly",
      value: {
        "week": "last"
      }
    }
    return this.http.post(this.data.goUrl + 'getUtilizationRds', body).pipe(
      map(response => {
        return response;
      })
    )
  }

}

export class ResponseDataTable {
  Resource: any = [];
  data: any = []
  stats: any = [];
}
