<div class="help-btn">
  <button class=" btn btn-cool-green  btn-wide help-button" (click)="fnOpenHelp()">? Help</button>
</div>



<!-- <div id="mySidenav" class="sidenav" *ngIf="showHelpBar"> -->
<!-- <a class="closebtn" (click)="fnCloseHelp()">&times;</a>
  <a href="#">About</a>
  <a href="#">Services</a>
  <a href="#">Clients</a>
  <a href="#">Contact</a> -->
<section class="sidenav" *ngIf="showHelpBar">
  <h4> 
    Tips Section 
    <span class="pull-right btn btn-cool-green btn-xs tips-close" (click)="fnCloseHelp()">
      <span class="fa fa-times"></span>&nbsp;CLOSE
    </span>
  </h4>
  <div class="videos-section">
    <p class="white fw-700">VIDEOS</p>
    <ul class="tip-list">
      <li>How to add cloud providers?
        <iframe width="260" class="tip-videos" height="140" src="https://www.youtube.com/embed/zMb_S2CCTu0"
          frameborder="0" allowfullscreen=""></iframe>
      </li>
      <li>How to create schedule and see Cost and Saving 
        <iframe width="260" class="tip-videos" height="140" src="https://www.youtube.com/embed/W2GmaFhXPuE"
          frameborder="0" allowfullscreen=""></iframe>
      </li>
    </ul>
  </div>
  <div class="videos-section">
    <p class="white fw-700">FAQS</p>
    <ul class="faq-list">

      <li class="faq-item faq-show"><span class="faq-q">How to add cloud providers?</span>
        <div class="faq-a">
          <p class="white">A provider can be created from providers page while proving Aws, Azure and Gcp, credential are saving to access cloud resources as per cloud provider guideline to manage by thirtd party/partner
            etc?</p>
        </div>
      </li>
      <li class="faq-item faq-show"><span class="faq-q">How to create a schedule?</span>
        <div class="faq-a">
          <p class="white">A default schedule is shown as per resource utilization but user and override schedule as per their need. User can able to see Cost and Saving as per applied schedule for last week.
            etc?</p>
        </div>
      </li>
      <li>
        <div class="all-tips text-center">
          <button type="button" class="btn btn-cool-green">SEE ALL TIPS</button>
        </div>
      </li>
    </ul>
  </div>
</section>
<!-- </div> -->
