import { Component, OnInit, Input, ViewChild, Output, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/data.service';
import { Router, NavigationEnd } from '@angular/router';
import { IamServiceService } from '../../iam-service.service';
@Component({
  selector: 'app-subgroup',
  templateUrl: './subgroup.component.html',
  styleUrls: ['./subgroup.component.scss']
})
export class SubgroupComponent implements OnInit {
  @Output('deleteGroupModal') public deleteGroupModal;
  // @ViewChild('deleteGroupModal', { static: false }) public deleteGroupModal;
  @ViewChild('addEditSubGroupModal', { static: false }) public addEditSubGroupModal;
  @Input() parent: any
  @Input() subgroups: any;
  @Input() actType: any;
  @Input() selGroupID: any;
  @Input() userID: any;

  group: any = [];
  editGroup: any = [];
  //len: Number = 0;
  len: any = 0;
  addEditSubGroup: any = { "ID": 0, "NEW_TITLE": "","OLD_TITLE": "", "PARENT": 0 };
  selGroup: any = {};
  modalId = "";
  addSubGroup: number = 0;
  delSubGroup: number = 0;

  public loading: boolean = false;
  public successMessage = "";
  public successDelMessage = "";
  errorMessage = '';

  mySubscription: any

  constructor(private http: HttpClient,
    private data: DataService,
    private router: Router,
    private iamservice: IamServiceService) {
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };
    // this.mySubscription = this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     // Trick the Router into believing it's last link wasn't previously loaded
    //     this.router.navigated = false;
    //   }
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    
    if(changes.subgroups) {
      this.subgroups = changes.subgroups.currentValue;
    }
    if(changes.parent) {
      this.parent = changes.parent.currentValue;
    }
    if(changes.actType) {
      this.actType = changes.actType.currentValue;
    }
    if(changes.selGroupID) {
      this.selGroupID = changes.selGroupID.currentValue;
    }
    if(changes.userID) {
      this.userID = changes.userID.currentValue;
    }

    // this.getChildren(this.iamservice.groups);
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.modalId = "#addEditSubGroupModal-" + this.subgroups;
    this.getChildren(this.iamservice.groups);
    this.iamservice.selectedGroupID = this.selGroupID;
  }

  fnSelectGroup(id: any, index: any) {
    this.iamservice.selectedGroupID = id;
  }

  getChildren(grps: any) {
     if (this.group.length > 0) {
      this.group = [];
    }
    if (grps == null) return;
    for (var i = 0; i < grps.length; i++) {
      if (grps[i].DETAILS.ID == this.subgroups) {
        for (var j = 0; j < grps[i].CHILDREN.length; j++) {

             this.group.push(grps[i].CHILDREN[j]);
        }
        this.len = this.group.length;
        return;
      } else {
        console.log("do not show children, as we do not support")
      }
    }
  }

  fnAddSubGroupModal(gr: any, pid: any) {
    if (pid != 0) {
      if (gr == null) {
        gr = this.iamservice.groups[0];
      }
      this.selGroup = gr;
      this.addEditSubGroup.ID = 0;
      this.addEditSubGroup.PARENT = pid;
      this.addSubGroup = pid;
      this.addEditSubGroup.TITLE = "";
    } else {
      this.selGroup = gr;
      this.addEditSubGroup.ID = gr.DETAILS.ID;
      this.addSubGroup = gr.DETAILS.ID;
      this.addEditSubGroup.PARENT = gr.DETAILS.PARENT;
      this.addEditSubGroup.OLD_TITLE = gr.DETAILS.TITLE;
      this.addEditSubGroup.NEW_TITLE = gr.DETAILS.TITLE;
    }

    console.log("aaaa - > ", gr)
  }

  fnAddGroupReset() {
    this.selGroup = {};
    this.addEditSubGroup.ID = 0;
    this.addEditSubGroup.PARENT = 0;
    this.addEditSubGroup.NEW_TITLE = "";
    this.addEditSubGroup.OLD_TITLE = "";
    this.addSubGroup = 0;
  }

  fnDelGroupReset() {
    this.selGroup = {};
    this.addEditSubGroup.ID = 0;
    this.addEditSubGroup.PARENT = 0;
    this.addEditSubGroup.OLD_TITLE = "";
    this.addEditSubGroup.NEW_TITLE = "";
    this.delSubGroup = 0;
    this.iamservice.refreshGroup(true);
    this.getChildren(this.iamservice.groups);
  }


  fnAddEditSubGroup() {
    this.loading = true;
    let payload: any = {};
    if (this.addEditSubGroup.ID == 0) {
      payload.ID = this.addEditSubGroup.ID;
      payload.TITLE = this.addEditSubGroup.NEW_TITLE;
      payload.OLD_TITLE = this.addEditSubGroup.OLD_TITLE;
      payload.PARENT = this.addEditSubGroup.PARENT;
    } else {
      payload.ID = this.addEditSubGroup.ID;
      payload.TITLE = this.addEditSubGroup.NEW_TITLE;
      payload.OLD_TITLE = this.addEditSubGroup.OLD_TITLE;
      payload.PARENT = this.addEditSubGroup.PARENT;
    }
    this.http
      .post<any>(
        this.data.loginURL + 'addGroup',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        console.log("Step - 1");
        if (this.addEditSubGroup.ID == 0) {
          this.selGroup.CHILDREN.push({DETAILS: resp.MAIN.RESULT.RESPONSE, CHILDREN: []});

          setTimeout(() => {
            this.ngOnInit();
          }, 1500);
          console.log("Step - 2");
          this.successMessage = "Team has been added successfully";
        } else {
          // console.log(resp)
          if(resp.MAIN.RESULT.RESPONSE_CODE == 500) {
            this.errorMessage = resp.MAIN.RESULT.RESPONSE
          } else{
            console.log("Step - 3");
            this.fnEditTeamResource(payload)
            this.fnEditTeamProvider(payload)
            this.selGroup.DETAILS = resp.MAIN.RESULT.RESPONSE;
            this.successMessage = "Team has been updated successfully";
          }
          
          // this.selGroup.CHILDREN = this.selGroup.CHILDREN.map((g: any)=>{
          //   if(g.ID === this.addEditSubGroup.ID) {
          //     g = resp.MAIN.RESULT.RESPONSE;
          //   }

          //   return g;
          // });

          
        }
        console.log("kkkkk---> ",this.selGroup);
        console.log("Step - 4");
        this.loading = false;
        this.iamservice.refreshGroup(true);
        setTimeout(() => {
          this.successMessage = ""
          this.errorMessage = ""
        }, 10000);
        setTimeout(() => {
          this.fnAddGroupReset();
        }, 1000);
      }, error => {
        this.loading = false;

        setTimeout(() => {
          this.fnAddGroupReset();
        }, 1000);
      });
  }

  fnDeleteSubGroup(id: any) {
    this.delSubGroup = id;
    console.log(this.delSubGroup);
    // this.deleteGroupModal.show();
  }

  async fnDeleteGroup() {
    this.loading = true;

    this.http.get<any>(this.data.loginURL + 'delGroup/' + this.delSubGroup).subscribe(resp => {
      if(resp.result == 'success'){
        this.successDelMessage = "Team has been deleted successfully";
        setTimeout(() => {
          this.fnDelGroupReset();
        }, 1000);
        // unassigned this team from provider and resource
        this.fnUnassignedTeamResource()
        this.fnUnassignedTeamProvider()
        
      }else{
        this.successDelMessage = resp.data
        setTimeout(() => {
          this.fnDelGroupReset();
        }, 1000);
      }

    }, error => {
      setTimeout(() => {
        this.fnDelGroupReset();
      }, 1000);
    });
  }
async fnUnassignedTeamResource(){
  await this.http.get<any>(this.data.goUrl + 'unassignedTeamResource?team_id=' + this.delSubGroup).subscribe(resp => {
    if(resp.result == 'success'){
      this.successDelMessage = "Team unassigned from resources successfully";
      setTimeout(() => {
        this.fnDelGroupReset();
      }, 1000);
    }
  })
}
async fnUnassignedTeamProvider(){
  await this.http.get<any>(this.data.goUrl + 'unassignedTeamProvider?team_id=' + this.delSubGroup).subscribe(resp => {
    if(resp.result == 'success'){
      this.successDelMessage = "Team unassigned from providers successfully";
      setTimeout(() => {
        this.fnDelGroupReset();
      }, 1000);
    }
  })
}
async fnEditTeamResource(payload:any){
  await this.http.post<any>(this.data.goUrl + 'editTeamResource',payload, { headers: this.data.headers() }).subscribe(resp => {
    if(resp.result == 'success'){
      this.successDelMessage = "Team edited for resources successfully";
      setTimeout(() => {
        this.fnDelGroupReset();
      }, 1000);
    }
  })
}
async fnEditTeamProvider(payload:any){
  await this.http.post<any>(this.data.goUrl + 'editTeamProvider',payload, { headers: this.data.headers() }).subscribe(resp => {
    if(resp.result == 'success'){
      this.successDelMessage = "Team edited for providers successfully";
      setTimeout(() => {
        this.fnDelGroupReset();
      }, 1000);
    }
  })
}
  fnCloseArea() {
  }

  showSubGroup: any;

  fnOpenArea(id: any) {


    if (this.showSubGroup == id) {
      this.showSubGroup = 0;
    } else {
      this.showSubGroup = id;
    }
  }

}
export class DataTablesResponse {
  status_code: any;
  data: any;
  session_id: any;
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}