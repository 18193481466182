<div class="below-navbar">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h3 class="below-nav-heading">
          Add Credentials
        </h3>
      </div>
    </div>
  </div>
</div>

<div class="middle-bar  animated fadeInDown" [class.sticky]="sticky" #stickyMenu>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-5 col-sm-12">
            <button type="button" style="font-weight: 300; height: 40px;" class="btn btn-success btn-create-green" (click)="fnGoBack()">
              <span>
                <i class="fa fa-backward mr-2"></i>
              </span>
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="container" *ngIf="prodService.providerType == 'aws'">
  <div class="mt-3">
    <div class="box">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-md-6 col-sm-12 br-right">
              <div style="width: 90%;">
                <form>
                  <div class="form-group">
                    <label for="title">
                      Title
                    </label>
                    <input type="text" id="title" class="form-control" [(ngModel)]="providerDetails.title" name="title" />
                  </div>
                  <!-- <div class="form-group">
                    <label for="team">
                      Team
                    </label>
                    <select class="form-control" id="inputGroupSelect01">
                      <option selected>Choose...</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div> -->
                  <p class="admin-info">
                    Give this information to your AWS administrator:
                  </p>
                  <div class="admin-info-box">
                    <p>
                      Create an IAM Role for cross-account access between your account and a 3rd party with:
                    </p>
                    <div>
                      <div style="width: 136px; font-size: 14px;" class="d-inline-block">
                        Account Number
                      </div>
                      <div style="font-size: 14px;" class="d-inline-block">
                        <strong>
                          {{accountInto}}
                          <span (click)="copyText(accountInto, 'acc')">
                            <i class="fa fa-files-o ml-2"></i>
                          </span>
                          <small class="ml-2" *ngIf="accCopied">
                            {{copyMessage}}
                          </small>
                        </strong>
                      </div>
                    </div>
                    <div>
                      <div style="width: 136px; font-size: 14px;" class="d-inline-block">
                        External ID
                      </div>
                      <div style="font-size: 14px;" class="d-inline-block">
                        <strong>
                          {{externalId}}
                        </strong>
                        <span (click)="copyText(externalId, 'extid')">
                          <i class="fa fa-files-o ml-2"></i>
                        </span>
                        <small class="ml-2" *ngIf="extIdCopied">
                          {{copyMessage}}
                        </small>
                      </div>
                    </div>
                  </div>
                  <p class="admin-info">
                    Get this information from your AWS administrator:
                  </p>
                  <div class="form-group">
                    <label for="arnNumber">
                      ARN for IAM Role
                    </label>
                    <input type="text" id="arnNumber" class="form-control" [(ngModel)]="providerDetails.arn" name="arn" />
                  </div>
                  <div class="form-group">
                    <!-- <button class="btn btn-default mr-2" (click)="fnGoBack()">
                      Back
                    </button> -->
                    <label for="arnNumber">
                      Email notification frequncy for current week running schedule
                    </label>
                    <div class="box mb-0 w-150">
                      <label class="w-50">
                      <input type="radio" [value]="1" name="notification" 
                                            (change)="fnSelectNotification(1)" />
                                          <span class="label-text">
                                            Hourly 
                                          </span>
                      </label>  
                      <label class="w-50">                  
                        <input type="radio" [value]="24" name="notification" 
                                            (change)="fnSelectNotification(24)" checked />
                                          <span class="label-text">
                                            Daily 
                                          </span>
                      </label>
                      <label class="w-50">                  
                        <input type="radio" [value]="7" name="notification" 
                                            (change)="fnSelectNotification(7)" />
                                          <span class="label-text">
                                            Weekly 
                                          </span>
                      </label>
                    </div>
                    <br>
                    <button class="btn btn-success" (click)="fnSubmitProvidersDetails('aws')">
                      <span *ngIf="!showTestBtnLoader">
                        Test
                      </span>
                      <span *ngIf="showTestBtnLoader">
                        Test
                        <i style="font-size: 17px;" class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                      </span>
                    </button>

                    <button class="btn btn-default ml-2" (click)="fnAddProvider()" [disabled]="!testSuccess">
                      Save
                    </button>
                    <br>

             
                    <div class="ml-2" style="font-size: 0.9rem; text-transform: capitalize;">
                      <span *ngIf="showTestMessage">{{testSuccessText}}</span>
                      <p class="d-inline-block mb-0" style="font-weight: 600;" *ngIf="!showTestMessage">
                        Please Wait
                        <span id="el1">.</span>
                        <span id="el2">.</span>
                        <span id="el3">.</span>
                      </p>
                    </div>

                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="policy-box">
                <div style="width: 90%; margin-left: auto;">
                  <h6>Help for
                    <strong>AWS</strong> Authentication</h6>
                  <p>
                    1. Enterprise or customer should have to Create Policy in AWS IAM as given below (Below is policy JSON)
                  </p>
                  <div class="json-box">
                    <span (click)="copyTheText(iamJson, 'iJson')">
                      <i class="fa fa-files-o ml-2"></i>
                    </span>
                    <small class="ml-2" *ngIf="iamJsonCopy">
                      {{copyMessage}}
                    </small>
                    <pre [innerHTML]="iamJson | json"></pre>
                  </div>
                  <p>
                    2. Enterprise or customer need to create Role while selecting "Another AWS Account" option, and should need to enter Windmill Optima
                    provided Account ID and External ID.
                  </p>
                  <p>
                    3. Enterprise or customer need to give any name to role and attach recently created policy
                  </p>
                  <p class="mb-2">
                    4. Enterprise or customer need to add below in "Trust Relationships" tabe for recently created role
                  </p>
                  <div class="json-box">
                    <!-- <i class="fa fa-files-o ml-2" (click)="copyText(trustRelatonshipJson, 'trustJson')"></i> -->
                    <span (click)="copyTheText(trustRelatonshipJson, 'trjson')">
                      <i class="fa fa-files-o ml-2"></i>
                    </span>
                    <small class="ml-2" *ngIf="trJsonCopy">
                      {{copyMessage}}
                    </small>
                    <pre [innerHTML]="trustRelatonshipJson | json"></pre>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="policy-link">
                <p>
                  <strong>NOTE:</strong> WINDMILL is not storing any secret and access key for AWS account, we are using AWS recommended
                  guideline for third party access, pls go through
                  <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user_externalid.html" target="_blank" title="How to Use an External ID When Granting Access to
                    Your AWS Resources to a Third Party">this</a>
                  link to get more information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="container" *ngIf="prodService.providerType == 'azure'">
  <div class="mt-3">
    <div class="box">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-md-6 col-sm-12 br-right">
              <div style="width: 90%;">
                <p>
                  General
                </p>
                <form>
                  <div class="form-group">
                    <label for="credentialName">
                      Credentials Name
                    </label>
                    <input type="text" id="credentialName" class="form-control" [(ngModel)]="azureProdDetails.Title" name="title" />
                  </div>
                  <p>
                    Azure Credentials Information
                  </p>
                  <div class="form-group">
                    <label for="subsId">
                      Subscription ID
                    </label>
                    <input type="text" id="subsId" class="form-control" name="subsId" [(ngModel)]="azureProdDetails.AbsInfo.SubscriptionID" />
                  </div>
                  <div class="form-group">
                    <label for="tenantId">
                      Tenant ID
                    </label>
                    <input type="text" class="form-control" id="tenantId" name="directoryId" [(ngModel)]="azureProdDetails.AbsInfo.TenantID"
                    />
                  </div>
                  <div class="form-group">
                    <label for="appId">
                      Application ID (Client ID)
                    </label>
                    <input type="text" id="appId" class="form-control" name="appId" [(ngModel)]="azureProdDetails.AbsInfo.ClientID" />
                  </div>
                  <div class="form-group">
                    <label for="clientSecretId">
                      Client Secret ID
                    </label>
                    <input type="text" id="appId" class="form-control" name="appId" [(ngModel)]="azureProdDetails.AbsInfo.ClientSecret" />
                  </div>
                  <div class="form-group">
                    <label for="arnNumber">
                      Email notification frequncy for current week running schedule
                    </label>
                    <div class="box mb-0 w-150">
                      <label class="w-50">
                      <input type="radio" [value]="1" name="notification" 
                                            (change)="fnSelectNotification(1)" />
                                          <span class="label-text">
                                            Hourly 
                                          </span>
                      </label>  
                      <label class="w-50">                  
                        <input type="radio" [value]="24" name="notification" 
                                            (change)="fnSelectNotification(24)" checked />
                                          <span class="label-text">
                                            Daily 
                                          </span>
                      </label>
                      <label class="w-50">                  
                        <input type="radio" [value]="7" name="notification" 
                                            (change)="fnSelectNotification(7)" />
                                          <span class="label-text">
                                            Weekly 
                                          </span>
                      </label>
                    </div>
                    <br>
                    <button type="submit" class="btn btn-success" (click)="fnSubmitProvidersDetails('azure')">
                      <span *ngIf="!showTestBtnLoader">
                        Test
                      </span>
                      <span *ngIf="showTestBtnLoader">
                        Test
                        <i style="font-size: 17px" class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                      </span>
                    </button>
                    <button class="btn btn-default ml-2" (click)="fnAddProvider()" [disabled]="!testSuccess">
                      Save
                    </button>
                    <div class="ml-2" style="font-size: 0.9rem; text-transform: capitalize;">

                      <span *ngIf="showTestMessage" class="text-center ">{{testSuccessText}}</span>
                      <p class="d-inline-block mb-0" style="font-weight: 600;" *ngIf="!showTestMessage">
                        Please Wait
                        <span id="el1">.</span>
                        <span id="el2">.</span>
                        <span id="el3">.</span>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="policy-box">
                <div style="width: 90%; margin-left: auto;">
                  <h6>Instruction for
                    <strong>Azure</strong> Provider</h6>
                  <ol class="pl-0">
                    <li>
                      Get your Azure Active Directory ID.
                      <ol>
                        <li>Sign in to the Azure Portal</li>
                        <li>
                          Select "Azure Active Directory" on the left navigation panel
                        </li>
                        <li>Go to the directory's "Properties" page</li>
                        <li>
                          Copy & paste the directory's "Directory ID" below
                        </li>
                      </ol>
                    </li>
                    <li>
                      Create a directory "app" for Windmill Optima
                      <ol>
                        <li>Go to the directory's "App registrations" page.</li>
                        <li>Click the "+New Registration" button</li>
                        <li>
                          <div class="w-10">
                            Enter the following information:
                          </div>
                          <div class="w-100">
                            <div class="d-inline-block">
                              Name:
                            </div>
                            <div class="d-inline-block">
                              Windmill Optima
                            </div>
                          </div>
                          <div class="w-100">
                            <div class="d-inline-block">
                              Supported Account Types:
                            </div>
                            <div class="d-inline-block">
                              Accounts in this organizational directory only (Default Directory only - Single tenant)
                            </div>
                          </div>
                          <div class="w-100">
                            <div class="d-inline-block">
                              Redirect URI (optional):
                            </div>
                            <div class="d-inline-block">
                              (Leave blank)
                            </div>
                          </div>
                        </li>
                        <li>Click "Register".</li>
                        <li>
                          The new app's properties should appear. Copy & paste the app’s "Application ID" below.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Create a "Key Value" for your new app
                      <ol>
                        <li>
                          Open app's properties page, click the "Certificates Á secrets"
                        </li>
                        <li>
                          Click the "+New client secret" button.
                        </li>
                        <li>
                          Provide friendly description and "Never Expires" for the duration
                        </li>
                        <li>
                          Click "Add".
                        </li>
                        <li>
                          Copy & paste the new key's "Value" into the "Key Value" field below. You cannot retrieve it again later.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Get your Azure Subscription ID.
                      <ol>
                        <li>
                          From the main Azure left navigation panel, click "Subscriptions". If you don't see it in the left navigation panel, find
                          it under "All Services".
                        </li>
                        <li>
                          Select your Azure subscription
                        </li>
                        <li>
                          Go to your subscription's "Overview" page, copy & paste the subscription's "Subscription ID" into the field below
                        </li>
                      </ol>
                    </li>
                    <li>
                      Create an IAM user for WindmillOptima
                      <ol>
                        <li>
                          Go to your subscription's "Access Control (IAM)" page
                        </li>
                        <li>
                          Click the "+ Add" button, and "Add role assignment".
                        </li>
                        <li>
                          Under "Role", choose your desired role for WindmillOptima. "Owner" is best fit but may want to choose a more restrictive role.
                        </li>
                        <li>
                          Under "Assign access to", choose "Azure AD user, group or application".
                        </li>
                        <li>
                          Under "Select", search for the "WindmillOptima" app you created above.
                        </li>
                        <li>
                          When it appears in the search results, click it's name. You should see it added under "Selected members".
                        </li>
                        <li>Click "Save".</li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <!-- <div class="col-12">
              <div class="policy-link">
                <p>
                  <strong>NOTE:</strong> Windmill Optima is not storing any secret and access key for AWS account, we are using AWS recommended
                  guideline for third party access, pls go through
                  <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user_externalid.html" target="_blank" title="How to Use an External ID When Granting Access to
                    Your AWS Resources to a Third Party">this</a>
                  link to get more information.
                </p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="container" *ngIf="prodService.providerType == 'gcp'">
  <div class="mt-3">
    <div class="box">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-md-6 col-sm-12 br-right">
              <div style="width: 90%;">
                <p>
                  General
                </p>
                <form>
                  <div class="form-group">
                    <label for="gcpCredentialName">
                      Credentials Name
                    </label>
                    <input type="text" id="gcpCredentialName" class="form-control" [(ngModel)]="gcpProdDetails.title" name="title" />
                  </div>
                  <div class="form-group">
                    <label for="gcpJson">
                      GCP Service Account Key JSON
                    </label>
                    <textarea name="json" id="gcpJson" cols="30" rows="5" class="form-control" [(ngModel)]="gcpProdDetails.json"></textarea>
                  </div>
                  <div class="form-group">
                    <label for="arnNumber">
                      Email notification frequncy for current week running schedule
                    </label>
                    <div class="box mb-0 w-150">
                      <label class="w-50">
                      <input type="radio" [value]="1" name="notification" 
                                            (change)="fnSelectNotification(1)" />
                                          <span class="label-text">
                                            Hourly 
                                          </span>
                      </label>  
                      <label class="w-50">                  
                        <input type="radio" [value]="24" name="notification" 
                                            (change)="fnSelectNotification(24)" checked />
                                          <span class="label-text">
                                            Daily 
                                          </span>
                      </label>
                      <label class="w-50">                  
                        <input type="radio" [value]="7" name="notification" 
                                            (change)="fnSelectNotification(7)" />
                                          <span class="label-text">
                                            Weekly 
                                          </span>
                      </label>
                    </div>
                    <br>
                    <button type="submit" class="btn btn-success" (click)="fnSubmitProvidersDetails('gcp')">
                      <span *ngIf="!showTestBtnLoader">
                        Test
                      </span>
                      <span *ngIf="showTestBtnLoader">
                        Test
                        <i style="font-size: 17px" class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                      </span>
                    </button>
                    <button class="btn btn-default ml-2" (click)="fnAddProvider()" [disabled]="!testSuccess">
                      Save
                    </button>
                    <div class="ml-2" style="font-size: 0.9rem; text-transform: capitalize;">
                      <span *ngIf="showTestMessage">{{testSuccessText}}</span>
                      <p class="d-inline-block mb-0" style="font-weight: 600;" *ngIf="!showTestMessage">
                        Please Wait
                        <span id="el1">.</span>
                        <span id="el2">.</span>
                        <span id="el3">.</span>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="policy-box">
                <div style="width: 90%; margin-left: auto;">
                  <h6>Instruction for
                    <strong>GCP</strong> Provider</h6>
                  <ol class="pl-0">
                    <li class="mb-2">
                      Sign in to the Google Cloud Platform console and go “
                      <strong>IAM and Admin</strong>” &#8594; “
                      <strong>Service Accounts</strong>”
                    </li>
                    <li class="mb-2">
                      Click the “
                      <strong>Create Service Account</strong>” button
                    </li>
                    <li class="mb-2">
                      Provide the new service account a name, such as “
                      <strong>WindmillOptima</strong>”
                    </li>
                    <li class="mb-2">
                      Click the “
                      <strong>Create</strong>” button
                    </li>
                    <li class="mb-2">
                      Pls assign a role as "editor" for newly create service account
                    </li>
                    <li class="mb-2">
                      Click on “
                      <strong>Continue</strong>” button
                    </li>
                    <li class="mb-2">
                      In the “
                      <strong>Create Key</strong>” section, click the “
                      <strong>Create Key</strong>” button
                    </li>
                    <li class="mb-2">
                      In the “
                      <strong>GCP Menu</strong>” , click the “
                      <strong>API and Service</strong>” and enable 
                      <strong>Compute Engine API</strong>
                    </li>
                    <li class="mb-2">
                      In the slide-out on the right side. Ensure
                      <strong>JSON</strong> is selected, then click the “
                      <strong>CREATE</strong>” button
                    </li>
                    <li class="mb-2">
                      A “
                      <strong>.json</strong>” file will be downloaded to your computer, Close the message box
                    </li>
                    <li class="mb-2">
                      Click the “
                      <strong>Done</strong>” button to return to the “
                      <strong>Service Account</strong>” page
                    </li>
                    <li class="mb-2">
                      Open the “
                      <strong>.json</strong>” file that was downloaded to your computer, and copy its’ contents to the “
                      <strong>GCP Service Account Key JSON</strong>” box
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <!-- <div class="col-12">
              <div class="policy-link">
                <p>
                  <strong>NOTE:</strong> WindmillOptima is not storing any secret and access key for AWS account, we are using AWS
                  recommended guideline for third party access, pls go through <a
                    href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user_externalid.html"
                    target="_blank"
                    title="How to Use an External ID When Granting Access to Your AWS Resources to a Third Party">this</a>
                  link to get more information.
                </p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>