import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(public data: DataService, private router: Router, private http: HttpClient) { }

  providerResponse: any = [];
  providerType: any;
  private compSource = new BehaviorSubject("No Data");
  successMessage: any = ''

  currentComp = this.compSource.asObservable();
  newProvider: any
  init: any

  changeComp(message: any) {
    this.compSource.next(message);
  }

  fnAddSimulator(data: any) {
    const url = `${this.data.goUrl + 'simulator'}`
    this.http.post<any>(url, data).subscribe(response => {
    })
  }

  fnAddProvider(data: any) {
    const url = `${this.data.goUrl + 'addProvider'}`
    this.http.post<DataTableResponse>(url, data).subscribe(response => {
      this.changeComp(response);
      this.providerResponse = response;
      // if(response.result == 'success') {

      // }
    })
  }

  // Bypass for now
  fnImportResource() {
    // mportResource?user_id=1234&prov_id=5e3430b0a1ac6cada9c776ba
    const url = `${this.data.goUrl + 'importResource'}?user_id=1234&prov_id=5e3815a187c93b455f9c1e69`;
    let payload: any = {};
    payload.user_id = '1234';
    payload.prov_id = '5e3430b0a1ac6cada9c776ba';
    this.http.get(url).subscribe(resp => {
    })
  }

  //resources?user_id=1234
  fnLoadResources() {
    const url = `${this.data.goUrl + 'resources'}?user_id=1234`;
    let payload: any = {};
    payload.user_id = '1234';
    this.http.post(url, payload).subscribe(resp => {
    })
  }

  fnGetUtilization() {
    // getUtilization?res_id=5e3585ee97b3678a47416f62&user_id=1234
    const url = `${this.data.goUrl + 'getUtilization'}?res_id=5e3585ee97b3678a47416f62&user_id=1234`;
    this.http.post(url, null).subscribe(resp => {
    })
  }

}


export class DataTableResponse {
  id: any;
  result: any;
}

export class ProvidersDataTable {
  Provider: any = []
  result: any
}