<div *ngIf="len==0" class="group-view" [ngClass]="{'no-br-left':parent=='0'}" style="margin-top:10px">
  <div *ngIf="subgroups == addSubGroup" style="margin-top:5px" class="form-group">
    <input type="text" class="form-control d-inline-block cfc ft-16 s-blue" style="width:200px" name="title"
      [(ngModel)]="addEditSubGroup.TITLE" placeholder="Group Title">
    <span class="fa fa-check" style="margin:5px;color: #32637a;" (click)="fnAddEditSubGroup()"
      title="Add Sub-Group"></span>
    <span class="fa fa-times" style="margin:5px;color: #32637a;" (click)="fnAddGroupReset()"
      title="Edit group title"></span>
    <p *ngIf="successMessage!==''" class="p-5 text-success text-center">{{successMessage}}</p>
  </div>

</div>
<!-- {{iamservice.groups[0].CHILDREN|json}} -->
<div  *ngIf="len!=0" class="group-view" [ngClass]="{'no-br-left':parent=='0'}" style="margin-top:10px">
  <div *ngFor="let g of group;let i=index" style="margin-top:10px">
    <!-- {{group | json}} -->
    <!--==================== Start: To be shown in Add User Component Modal ====================-->
    <div *ngIf="actType=='choose'" class="inblue">
      <input type="radio" (click)="fnSelectGroup(g.DETAILS.ID, i)" id={{g.DETAILS.ID}} class="s-blue"
       style="margin:5px" name="selectGroup" value="" checked>
      <label for={{g.DETAILS.ID}} class="label-text">{{g.DETAILS.TITLE}}</label>
    </div>
    <!--==================== End: To be shown in Add User Component Modal ====================-->

    <div *ngIf="actType!='choose'">
      <span class="group in-blue" style="margin-right:20px;font-size: 15px;color:#24a0ed">
        <span>
          <i style="font-size: 15px;color:#24a0ed" class="fa fa-users mr-2" (click)="fnOpenArea(g.DETAILS.ID)"></i>
        </span>
        {{g.DETAILS.TITLE}}
        <!-- (<span data-toggle="tooltip" data-placement="top" title="Resources">
          {{i + 1}}
        </span>,
        <span data-toggle="tooltip" data-placement="top" title="Score">
          {{i + 4}}
        </span>) -->
      </span>
      <!--span>
      </span-->
      <!--span class="fa fa-plus" style="margin:5px; color: #32637a;" (click)="fnAddSubGroupModal(g, g.DETAILS.ID)"
        title="Add Sub-Group"></span-->
      <span class="fa fa-edit" style="margin:5px; color: #32637a;" (click)="fnAddSubGroupModal(g, 0)"
        title="Edit group title"></span>
      <span class="fa fa-trash" style="margin:5px; color: #32637a;" (click)="fnDeleteSubGroup(g.DETAILS.ID)"
        title="Delete this group"></span>
      <div *ngIf="g.DETAILS.ID == addSubGroup" style="margin-top:5px" class="form-group">
        <input type="text" class="form-control d-inline-block cfc ft-16 s-blue" style="width:200px" name="title"
          [(ngModel)]="addEditSubGroup.NEW_TITLE" placeholder="Group Title">
        <span class="fa fa-check" style="margin:5px;color: #32637a;" (click)="fnAddEditSubGroup()"
          title="Add Sub-Group"></span>
        <span class="fa fa-times" style="margin:5px;color: #32637a;" (click)="fnAddGroupReset()"
          title="Edit group title"></span>
        <p *ngIf="successMessage!==''" class="p-5 text-success text-center">{{successMessage}}</p>
        <p class="text-center mb-0 text-danger" *ngIf="errorMessage !== ''">
          {{errorMessage}}
      </div>
    </div>
    <div *ngIf="g.DETAILS.ID == delSubGroup" class="group-view" style="margin-top:5px">
      <h3 class="mr-top-0 in-blue box-heading  fw-300"> Confirm Delete</h3>
      <p> Do you want to delete the team (this action will delete subgroups as well) <br />
        <span class="green" id="delete_survey_info"></span> ?
      </p>
      <p class="ft-12"> This Action can't be reversed</p>
      <button type="submit" class="btn btn-default btn-cool btn-cool-green mr-2"
        (click)="fnDeleteGroup()">Delete</button>
      <button type="button" class="btn btn-default btn-cool" (click)="fnDelGroupReset()">Cancel</button>
      <p *ngIf="successDelMessage!==''" class="p-5 text-success text-center">{{successDelMessage}}</p>
    </div>
    <app-subgroup subgroups={{g.DETAILS.ID}} actType={{actType}} selGroupID={{selGroupID}} userID={{userID}}>
    </app-subgroup>
  </div>
</div>
