import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { IamServiceService } from '../iam-service.service';
import { GlobalService } from 'src/app/stores/global.service';
import { DataService } from 'src/app/data.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @ViewChild('deleteUserModal', { static: false }) public deleteUserModal;
  @ViewChild('addEditUserModal', { static: false }) public addEditUserModal;
  // @ViewChild('userForm', { static: false }) public userForm;
  @ViewChild('editAccessPermissionsModal', { static: false }) public editAccessPermissionsModal;
  @ViewChild('deleteUserAlertModal', { static: false }) public deleteUserAlertModal;
  @ViewChild('stickyMenu', { static: false }) menuElement: ElementRef;
  constructor(
    public data: DataService,
    public iamService: IamServiceService,
    public globalStore: GlobalService,
    private http: HttpClient,
    private route: Router
  ) { }

  sticky: boolean = false;
  elementPosition: any;

  menuPosition: any;
  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll')

  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }



  dtOptions: DataTables.Settings = {};
  public start = 0;
  public limit = 10;
  public loading: boolean = false;
  public users: any = [];
  public roles: any = [];
  public designations: any = [];
  public user: any = {};
  public userID: any = 0;
  public groups: any = [{ "DETAILS": { "ID": "" } }];
  public grlen: any = 0;
  public selGroupID: any = 0;
  public USER_ID: any = "";
  public NAME: any = "";
  public MOBILE: any = "";
  public EMAIL: any = "";
  public ROLE_ID: any = "";
  public addUser: any = { ID: "", NAME: "", USERID: "", MOBILE: "", role_id: "" };
  public addRole: any = {};
  public addEditPermissions: any = [];
  public confirmationMessage: any = "";
  public alertDate: Date;
  public loaders: any = { role_id: 0, activateLoader: false, editLoader: false };
  public deleteUserOptions = [{ id: 1, title: 'Suspend' }, { id: 2, title: 'Terminate' }];
  public email:any;
  public isGroups = false;

  public removed: any
  public successMessage = "";
  errorMessage = '';
  tmpArr: any = [];
  searchUsr: any = ''
  public roleStruc: any = {
    dataJson: []
  };
  p: any
  showLoader: boolean = false
  parengExist:boolean=true
  parentNotExist:boolean=false
  ngOnInit() {


    this.email = sessionStorage.getItem('userId');
    this.fnGetGroups();
    this.fnGetUserRoles();
    this.fnGetUsers();


 
  }

  groupAssigned:boolean=false
  parentGroupId:any
 
  fnGetUsers(): void {
    this.loading = true;
    let payload: any = {};
    payload.start = this.start;
    payload.length = this.limit;
    payload.session_id = this.globalStore.SESSION_ID;
    this.http
      .post<DataTablesResponse>(
        this.data.loginURL + 'users',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        this.users = resp.data;
        this.tmpArr = this.users
        this.loading = false;
        //////////////////////
        for (var i = 0; i < this.users.length; i++){
          if (this.users[i].USERID != this.email){
            if (this.groups[0].CHILDREN != null){ ///if Login user is not created teams then CHILDREN would be NULL
              for (var j = 0; j < this.groups[0].CHILDREN.length; j++){ ///First group assignement has rights to creates team
                for ( var k = 0; k <  this.users[i].groups.length ; k++){
                  if (this.users[i].groups[k].DETAILS == null){
                    this.groupAssigned = false
                    break                   
                  }
                  if (this.groups[0].CHILDREN[j].DETAILS.ID == this.users[i].groups[k].DETAILS.ID){
                      this.groupAssigned = true
                      break
                  }
                }
              }
            } 
            if  (this.groupAssigned == false) {
              for ( var k = 0; k <  this.users[i].groups.length ; k++){
                //alert("Delete existing mapping if available and assigned to parent ")
                let payload: any = {};
                payload.USER_ID = this.users[i].ID;
                if (this.users[i].groups[k].DETAILS == null){
                  continue
                }
                payload.GROUP_ID = this.users[i].groups[k].DETAILS.ID;
                if (this.users[i].groups[k].DETAILS.PARENT != "0"){
                this.http
                  .post<DataTablesResponse>(
                    this.data.loginURL + 'delGroupMapSudden',
                    payload, { headers: this.data.headers() }
                  ).subscribe(resp => {
                    
                    setTimeout(() => {
                      this.successMessage = "";
                      this.loading = false;
                    }, 1000);

                  });
                }
              }
            }
            /////////////////
            if (this.groupAssigned == false && this.users[i].USERID != this.email){
             // alert("Adding Parent.......")
              let payload: any = {};
              payload.USER_ID = this.users[i].ID;
              payload.GROUP_ID = this.parentGroupId;
              this.http
                .post<DataTablesResponse>(
                  this.data.loginURL + 'addGroupMap',
                  payload, { headers: this.data.headers() }
                ).subscribe(resp => {
                  setTimeout(() => {
                    this.users[i].groups = resp.data;
                    this.successMessage = "";
                    this.loading = false;
                  }, 1000);
                });
            }
            /////////////////////
          } else{
            this.parentGroupId = this.users[i].groups[0].DETAILS.ID;
          }

        }

      });
  }

  fnGetUserRoles(): void {
    this.ROLE_ID = 0
    let payload = { session_id: this.globalStore.SESSION_ID };
    this.http
      .post<DataTablesResponse>(
        this.data.loginURL + 'roles',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        this.roles = resp.data;
      });
  }

  fnAddEditUser(): void {
    this.loading = true;
    this.showLoader = true
    let payload: any = {};
    payload.ID = this.USER_ID;
    payload.NAME = this.NAME;
    payload.USERID = this.EMAIL;
    payload.MOBILE = this.MOBILE;
    payload.role_id = Number(this.ROLE_ID);

    this.http
      .post<DataTablesResponse>(
        this.data.loginURL + 'addUser',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        if (resp.status_code == 500) {
          this.showLoader = false;
          this.successMessage = '';
          this.errorMessage = "User info not saved! " + resp.data;
          setTimeout(() => {
            $("#addEditUserModal").hide();
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
          }, 3000);



        } else {
          if (this.USER_ID > 0) {
            var i = 0;
            for (i = 0; i < this.users.length; i++) {
              if (this.users[i].ID === this.USER_ID) {
                this.users[i] = resp.data;
                this.successMessage = "User info saved successfully.";
                this.errorMessage = "";
                break;
              }
            }
          } else {
            this.users.splice(0, 0, resp.data);
            this.errorMessage = "";
            this.successMessage = "User has been created successfully, an email has been sent to the user with the link for further steps.";
          }
          this.loading = false;

          setTimeout(() => {
            this.fnCloseAddEditUserModal();
          }, 3000);
        }
      }, error => {
        this.loading = false;

        setTimeout(() => {
          this.fnCloseAddEditUserModal();
        }, 3000);
      });
  }

  fnGroupModal(id: any) {
    this.userID = id;
  }

  fnAddGroup(): void {
    this.loading = true;
    let payload: any = {};
    payload.USER_ID = this.userID;
    payload.GROUP_ID = this.iamService.selectedGroupID;
    this.http
      .post<DataTablesResponse>(
        this.data.loginURL + 'addGroupMap',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {

        for (var i = 0; i < this.users.length; i++) {
          if (this.userID == this.users[i].ID) {
            this.users[i].groups = resp.data;
            break;
          }
        }

        this.successMessage = "Group assigned successfully!";

        this.loading = false;

        setTimeout(() => {
          this.userID = 0;
          this.iamService.selectedGroupID = 0;
          $("#addGroupModal").hide();
          $("#addGroupModal").removeClass('show');
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
        }, 3000);
      }, error => {
        this.loading = false;

        setTimeout(() => {
          $("#addGroupModal").removeClass('show');
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
        }, 3000);
      });
  }

  fnDelGroupMap(grID: any, userID: any) {
    this.loading = true;
    let payload: any = {};
    payload.USER_ID = userID;
    payload.GROUP_ID = grID;
    this.http
      .post<DataTablesResponse>(
        this.data.loginURL + 'delGroupMap',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {

        this.successMessage = "Group removed successfully!";

        this.loading = false;

        setTimeout(() => {
          this.userID = 0;
          this.iamService.selectedGroupID = 0;
          $("#addGroupModal").hide();
          $("#addGroupModal").removeClass('show');
        }, 3000);
      }, error => {
        this.loading = false;

        setTimeout(() => {
          $("#addGroupModal").removeClass('show');
          this.fnCloseAddEditUserModal();
        }, 3000);
      });
  }

  fnAddEditUserModal(id: any = ""): void {
    this.errorMessage = "";
    this.successMessage = "";
    for (var i = 0; i < this.users.length; i++) {
      if (this.users[i].ID == id) {
        this.USER_ID = this.users[i].ID;
        this.EMAIL = this.users[i].USERID;
        this.NAME = this.users[i].NAME;
        this.MOBILE = this.users[i].MOBILE;
        this.ROLE_ID = this.users[i].ROLE_ID;
        break;
      }
    }
  }

  fnCloseAddEditUserModal(): void {
    this.USER_ID = 0;
    this.EMAIL = "";
    this.NAME = "";
    this.MOBILE = "";
    this.ROLE_ID = 0;
    this.successMessage = "";
    setTimeout(() => {
      this.showLoader = false
      this.successMessage = "User info saved successfully."
      setTimeout(() => {
        $("#addEditUserModal").hide();
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        this.showLoader = false
        this.successMessage = ""
      }, 3000)
    }, 1500)
  }

  fnDeleteUser() {
    this.loading = true;
    this.showLoader = true
    let payload: any = {};
    payload.ID = this.USER_ID;
    payload.NAME = this.NAME;
    payload.USERID = this.EMAIL;
    payload.MOBILE = this.MOBILE;
    payload.role_id = Number(this.ROLE_ID);
    this.http
    .post<DataTablesResponse>(
      this.data.loginURL + 'deleteUser',
      payload, { headers: this.data.headers() }
    ).subscribe(resp => {
      if (resp.status_code == 500) {
        this.showLoader = false;
        this.successMessage = '';
        this.errorMessage = "User info not deleted! " + resp.data;
        setTimeout(() => {
          $("#deleteUserModal").hide();
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
        }, 3000);
      } else {
        for (var i = 0; i < this.users.length; i++) {
          if (this.users[i].ID == this.USER_ID) {
            this.users.pop(i)
            break;
          }
        }
        this.errorMessage = "";
        this.successMessage = "User has been deleted successfully";
        this.loading = false;

        setTimeout(() => {
          this.fnCloseDeleteUserModal();
        }, 1500);
      }
    }, error => {
      this.loading = false;

      setTimeout(() => {
        this.fnCloseDeleteUserModal();
      }, 1500);
    });
    
  }

  fnDoNotDeleteUser() {
  }

  // fnShowDeleteUserModal(id): void {
  //   if (id !== "") {
  //     this.user = this.users.filter((user) => user.ID === id)[0];
  //     $("#deleteUserModal").show();
  //     $("#deleteUserModal").addClass('show');
  //   }
  // }

    fnDeleteUserModal(id: any = ""): void {
      this.errorMessage = "";
      this.successMessage = "";
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].ID == id) {
          this.USER_ID = this.users[i].ID;
          this.EMAIL = this.users[i].USERID;
          this.NAME = this.users[i].NAME;
          this.MOBILE = this.users[i].MOBILE;
          this.ROLE_ID = this.users[i].ROLE_ID;
          break;
        }
      }
  }

  fnCloseDeleteUserModal(): void {
    this.USER_ID = 0;
    this.EMAIL = "";
    this.NAME = "";
    this.MOBILE = "";
    this.ROLE_ID = 0;
    this.successMessage = "";
    setTimeout(() => {
      this.showLoader = false
      this.successMessage = ""
      setTimeout(() => {
        $("#deleteUserModal").hide();
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        this.showLoader = false
        this.successMessage = ""
      }, 3000)
    }, 1500)
  }

  fnShowDeleteUserAlertModal(removed): void {
    //this.confirmationMessage = "Are you sure, you want to " + this.deleteUserOptions[removed - 1].title + " this subscriber?";
    // $("#deleteUserAlertModal").show();
    // $("#deleteUserAlertModal").addClass('show');
  }

  fnGetGroups(): void {
    this.loading = true;
    let payload: any = {};
    payload.start = this.start;
    payload.length = this.limit;
    payload.session_id = this.globalStore.SESSION_ID;
    this.http
      .post<DataTablesResponse>(
        this.data.loginURL + 'getGroups/details',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        if (resp.data[0].CHILDREN) {
          const lastChild = (resp.data[0].CHILDREN.length - 1);
          this.selGroupID = resp.data[0].CHILDREN[lastChild].DETAILS.ID;
        }
        this.groups = resp.data;
        this.grlen = this.groups.length;
        this.iamService.groups = resp.data;
        this.loading = false;
        this.isGroups = true;
      });
  }

  fnSearchUserFtr() {
    this.tmpArr = JSON.parse(JSON.stringify(this.users))
    this.tmpArr = this.tmpArr.filter((ts: any) => ts.NAME.toUpperCase().indexOf(this.searchUsr.toUpperCase()) > -1)
  }

  fnGoToResources() {
    this.route.navigate(['resources/overview'])
  }

}

export class DataTablesResponse {
  status_code: any;
  data: any;
  session_id: any;
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}