import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'app-help-float-bar',
  templateUrl: './help-float-bar.component.html',
  styleUrls: ['./help-float-bar.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class HelpFloatBarComponent implements OnInit {

  constructor() { }

  showHelpBar: boolean = false;

  fnOpenHelp() {
    this.showHelpBar = true;
  }

  fnCloseHelp() {
    this.showHelpBar = false;
  }

  ngOnInit() {
  }

}
