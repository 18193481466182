import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/data.service';
import { ProviderService } from '../provider.service';
import { Location } from '@angular/common';
import { GlobalService } from 'src/app/stores/global.service';
@Component({
  selector: 'app-initial-providers-dashboard',
  templateUrl: './initial-providers-dashboard.component.html',
  styleUrls: ['./initial-providers-dashboard.component.scss']
})
export class InitialProvidersDashboardComponent implements OnInit {

  @ViewChild('stickyMenu', { static: false }) menuElement: ElementRef;

  constructor(private router: Router,
    public http: HttpClient,
    public data: DataService,
    private _location: Location,
    public providerService: ProviderService,
    public globalStore: GlobalService
    ) { }

  providersImages: any = [];

  sticky: boolean = false;

  providerDetails: any = {};
  providerPath: any = "";
  simulatorProviderType: any;
  providerType: any;
  instancesCount: any;
  successMessage: any = ''
  showLoader: boolean = false;
  providerId: any
  aws: boolean = false;
  azure: boolean = false
  gcp: boolean = false
  success: boolean = false;
  resourceType: any = []
  instanceStatus: any[]
  selectedResourceType: any
  selectedInstanceStatus: any
  previousProviderSelected: any
  hidePathTextBox: boolean = false
  hideAddedSimulators: boolean = false
  userId :any
  simulatorDisable:boolean = true
  permissionmsg:any=''
  // Load Images on intial dashboard, Open different modals based on selected providers
  fnLoadProvidersImages() {
    this.providersImages = [
      { "src": "./assets/images/aws.png", "modal": "awsProviderModal", "disabled": 1, 'providerType': 'aws', "init": 'aws' },
      { "src": "./assets/images/azure.png", "modal": "azureProviderModal", "disabled": 1, 'providerType': 'azure', "init": 'azure' },
      { "src": "./assets/images/gcp.png", "modal": "gcpProviderModal", "disabled": 1, 'providerType': 'gcp', "init": 'gcp' },
    ]
  }
  elementPosition: any;
  resourceGroup: any

  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll')

  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  providerTitle: any;
  addedProvidersList: any = []
  score: any

  fnSubmitProvidersDetails(cloudType: any) {
    this.showLoader = true;
    let payload: any = {};
    var AbsInfo: any = {};
    payload.path = this.providerPath;
    this.providerTitle = this.providerPath
    payload.simulatortype = cloudType //"aws"
    payload.score = parseInt(this.score);
    if (this.distribution.length == 0) {
      if (this.azure)
        this.distribution.push({ 'instances': parseInt(this.instancesCount), 'instancetype': this.selectedResourceType, 'instancestatus': this.selectedInstanceStatus, 'resourcegroup': this.resourceGroup })
      else
        this.distribution.push({ 'instances': parseInt(this.instancesCount), 'instancetype': this.selectedResourceType, 'instancestatus': this.selectedInstanceStatus })
      this.count = this.count + parseInt(this.instancesCount)
    }
    payload.distribution = this.distribution
    payload.instances = this.count
    this.http.post<SimulatorDataTableResponse>(this.data.goUrl + 'simulator', payload).subscribe(resp => {
      if (resp.result == 'success') {
        let data: any = {};
        data.Title = this.providerTitle;
        AbsInfo.path = this.providerPath;
        data.AbsInfo = AbsInfo
        data.CloudType = cloudType + "simulator"; //payload.simulatortype + "simulator";
        if (!this.previousProviderSelected)
          this.fnAddProvider(data);
        else
          setTimeout(() => {
            this.showLoader = false;
            this.success = true;
            this.successMessage = this.count + ' Instances added successfully';

            setTimeout(() => {
              $("#simulatorModal").hide();
              $('body').removeClass('modal-open');
              $('.modal-backdrop').remove();
              this.router.navigate(['providers'])
            }, 3000)
          }, 1500)
      }
    })
  }

  fnAddProvider(data: any) {
    this.success = false;
    const url = `${this.data.goUrl + 'addProvider'}`
    this.http.post<DataTableResponse>(url, data).subscribe(response => {
      if (response.result == 'success') {
        this.providerService.newProvider = response.ID
        setTimeout(() => {
          this.showLoader = false;
          this.success = true;
          this.successMessage = 'Provider added successfully';

          setTimeout(() => {
            $("#simulatorModal").hide();
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            this.router.navigate(['providers'])
          }, 3000)
        }, 1500)
      } else if (response.result == 'failure') {
        setTimeout(() => {
          this.showLoader = false;
          this.success = false;
          this.successMessage = response.reason

          setTimeout(() => {
            $("#simulatorModal").hide();
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
          }, 3000)
        }, 1500)
      }

    })
  }

  fnOpenModal(modalType: any) {
    this.aws = false;
    this.azure = false
    this.gcp = false
    this.showLoader = false;
    this.resourceType = []
    this.instanceStatus = []
    this.showLoader = false;
    this.success = false;
    this.successMessage = ""
    this.addedProvidersList = []
    this.hideAddedSimulators = false
    this.hidePathTextBox = false
    this.distribution = []
    if (modalType == 'aws') {
      this.aws = true
      this.resourceType = ['t2.micro', 't2.medium', 't2.large', 't2.small']
      this.instanceStatus = ['RUNNING', 'STOPPED', 'TERMINATED']
      for (let i in this.providerService.providerResponse) {
        if (this.providerService.providerResponse[i].CloudType == 'awssimulator') {
          this.addedProvidersList.push(this.providerService.providerResponse[i])
          this.hidePathTextBox = true
        }
      }
    }
    if (modalType == 'azure') {
      this.azure = true
      this.resourceType = ['Standard_B1ms', 'Standard_F4']
      this.instanceStatus = ['VM running', 'VM stopped']
      for (let i in this.providerService.providerResponse) {
        if (this.providerService.providerResponse[i].CloudType == 'azuresimulator') {
          this.addedProvidersList.push(this.providerService.providerResponse[i])
          this.hidePathTextBox = true
        }
      }
    }
    if (modalType == 'gcp') {
      this.gcp = true
      this.resourceType = ['n1-standard-1', 'n2-standard-8']
      this.instanceStatus = ['RUNNING', 'TERMINATED']
      for (let i in this.providerService.providerResponse) {
        if (this.providerService.providerResponse[i].CloudType == 'gcpsimulator') {
          this.addedProvidersList.push(this.providerService.providerResponse[i])
          this.hidePathTextBox = true
        }
      }
    }
    if (this.addedProvidersList.length != 0)
      this.hideAddedSimulators = false
    else
      this.hideAddedSimulators = true
  }

  goToAddProviders(type: any, init: any) {
    this.providerService.providerType = type;
    this.providerService.init = init
    var i =0;
    for(i=0; i <this.globalStore.accessPermissions.length; i++  ){
      if (this.globalStore.accessPermissions[i].type == 'providers'){
        if (this.globalStore.accessPermissions[i].permission.add == "1"){
          this.router.navigate(['add-provider']);
        }else{
          this.permissionmsg = "you do not have permission to add providers"
          setTimeout(() => {
            this.permissionmsg = ""
          }, 3000);
        }
        break;
      }
    }
    
  }


  ngOnInit() {
    this.fnLoadProvidersImages()
    this.selectedResourceType = 0
    this.selectedInstanceStatus = 0
    this.previousProviderSelected = 0
    
    this.fnGetProfile()

      
  }

  async fnGetProfile() {
 
    await this.http.post<any>(this.data.loginURL + 'currentUser', null).subscribe(
      resp => {
        this.userId = resp.data[0].USERID;
          if (this.userId == "rakesh.khandelwal@cloudwindmill.com" ||
          this.userId == "tapanmathurdev@gmail.com" ){
            this.simulatorDisable = false
          }else{
            this.simulatorDisable = true
          }
      }
    ) 
  }

  fnGoBack() {
    this._location.back();
  }

  distribution: any = []
  count: number = 0
  fnAddInstances() {
    if (this.azure)
      this.distribution.push({ 'instances': parseInt(this.instancesCount), 'instancetype': this.selectedResourceType, 'instancestatus': this.selectedInstanceStatus, 'resourcegroup': this.resourceGroup })
    else
      this.distribution.push({ 'instances': parseInt(this.instancesCount), 'instancetype': this.selectedResourceType, 'instancestatus': this.selectedInstanceStatus })

    this.count = this.count + parseInt(this.instancesCount)
    // parseInt(this.instancesCount) = ' '
    this.selectedResourceType = ''
    this.selectedInstanceStatus = ''
    this.instancesCount = ''
  }

}

export class SimulatorDataTableResponse {
  result: any;
}

export class DataTableResponse {
  ID: any;
  result: any;
  reason: any
}