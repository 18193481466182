<div class="below-navbar">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h3 class="below-nav-heading">
          Profile
        </h3>
      </div>
    </div>
  </div>
</div>

<section class="mt-3" *ngIf="dataLoaded; else skeleton">
  <div class="container">
    <div classs="row" [@fadeIn]>
      <div class="col-12">
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <div class="box profile-box text-center">
              <img src="./assets/images/profile.jpg" class="profile-image">
              <h3 class="ft-20"></h3>
              <span class="light-line"></span>
              <div class="profile-stats">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12">
                        <p>PROFILE STATS</p>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <h3 class="blue media-heading ft-22">
                          {{resourcesCount}}
                        </h3>
                        <h4 class="s-grey media-heading  ft-12 fw-700">
                          Resources
                        </h4>
                      </div>
                      <div class="col-md-4 col-sm-12"
                        style="border-left:1px solid #c3dae9;border-right:1px solid #c3dae9;">
                        <h3 class="blue media-heading ft-22">
                          <span >
                            $
                          </span>
                          {{cost | number :'1.2-2'}}
                        </h3>
                        <h4 class="s-grey media-heading ft-12 fw-700">
                          Cost(wk)
                        </h4>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <h3 class="blue media-heading ft-22">
                          {{providersCount}}
                        </h3>
                        <h4 class="s-grey media-heading ft-12 fw-700">
                          Providers
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-sm-12 text-left">
            <div class="box">
              <div class="border-bottom">
                <h3 class="media-heading in-blue ft-16 d-inline-block">
                  Account Settings
                  <span class="pull-right glyphicon glyphicon-pencil pointer" data-toggle="modal"
                    data-target="#editSurveyor"></span>
                </h3>
              </div>
              <div class="profile text-left">
                <div class="custom-user-detail">
                  <div class="detail-item">
                    <span class="detail-type">User Name</span>
                    <span class="detail-descp">
                      {{profile}}
                    </span>
                  </div>
                  <div class="detail-item">
                    <span class="detail-type">Email</span>
                    <span class="detail-descp">{{userId}}</span>
                  </div>
                  <div class="detail-item">
                    <span class="detail-type">Phone</span>
                    <span class="detail-descp">{{mobile}}</span>
                  </div>
                  <div class="detail-item">
                    <span class="detail-type">Account Type</span>
                    <span class="detail-descp">Free <a class="link1 ft-12"> Upgrade Now</a></span>
                  </div>
                  <div class="detail-item">
                    <span class="detail-type">Clients</span>
                    <span class="detail-descp grren">{{client}}</span>
                  </div>
                  <!--div class="detail-item">
                    <span class="detail-type">Respondants</span>
                    <span class="detail-descp grren">1</span>
                  </div-->
                </div>
                <h3 class="media-heading in-blue ft-16 d-inline-block">
                  Your Role is {{rolename}} and Permission are:-
                  <span class="pull-right glyphicon glyphicon-pencil pointer" data-toggle="modal"
                    data-target="#editSurveyor"></span>
                </h3>
                <div class="row permission-box mb-3" *ngFor="let sr of selectRole">
                  <div class="col-md-3 col-sm-12 scope">
                    <p>
                      {{sr.scope}}
                    </p>
                  </div>
                  <div class="col-md-9 col-sm-12">
                    <div *ngFor="let prmsn of sr.permissions; let i = index">
                      <div class="row mb-2">
                        <div class="col-md-3 col-sm-12">
                          <span style="font-size: 14px; text-transform: capitalize;">
                            {{prmsn.type}}
                          </span>
                        </div>
                        <div class="col-md-9 col-sm-12">
                          <div class="d-inline-block w-25" >

                            <div class="granted-permission" 
                              (click)="disableUpdate ?null:fnChangeAccessPermission(i, sr.scope,'add',prmsn.permission.add)"
                              [ngClass]="{'1':'color-green','0':'color-red'}[prmsn.permission.add]"
                               >
                              Add
                              <i class="fa" [ngClass]="{'1':'fa-check','0':'fa-times'}[prmsn.permission.add]" ></i>
                            </div>

                          </div>
                          <div class="d-inline-block w-25"
                            (click)="disableUpdate ?null:fnChangeAccessPermission(i, sr.scope,'edit', prmsn.permission.edit)"
                            [ngClass]="{'1':'color-green','0':'color-red'}[prmsn.permission.edit]"
                            >
                            <div class="granted-permission">
                              Edit
                              <i class="fa" [ngClass]="{'1':'fa-check','0':'fa-times'}[prmsn.permission.edit]"></i>
                            </div>
                          </div>
                          <div class="d-inline-block w-25"
                            (click)="disableUpdate ?null:fnChangeAccessPermission(i, sr.scope,'delete', prmsn.permission.delete)"
                            [ngClass]="{'1':'color-green','0':'color-red'}[prmsn.permission.delete]"
                            >
                            <div class="granted-permission">
                              Delete
                              <i class="fa" [ngClass]="{'1':'fa-check','0':'fa-times'}[prmsn.permission.delete]"></i>
                            </div>
                          </div>
                          <div class="d-inline-block w-25"
                            (click)="disableUpdate ?null:fnChangeAccessPermission(i, sr.scope,'view', prmsn.permission.view)"
                            [ngClass]="{'1':'color-green','0':'color-red'}[prmsn.permission.view]"
                            >
                            <div class="granted-permission">
                              View
                              <i class="fa" [ngClass]="{'1':'fa-check','0':'fa-times'}[prmsn.permission.view]"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-right">
                  <button class="btn btn-default" data-toggle="modal" data-target="#resetPasswordModal"
                    data-backdrop="static" data-keyboard="false">
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #skeleton>
  <section class="mt-3 container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <div class="profile-skeleton-box"></div>
          </div>
          <div class="col-md-8 col-sm-12">
            <div class="profile-skeleton-box"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<div class="modal fade" id="resetPasswordModal" tabindex="-1" role="dialog" aria-labelledby="resetPasswordModalTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="resetPasswordModalTitle">
          Reset Password
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form (submit)="fnResetPassword()">
          <div class="form-group">
            <label for="currentPassword">
              Current Password
            </label>
            <input type="password" id="currentPassword" class="form-control" [(ngModel)]="resetPassword.currentPassword"
              name="currentPassword">
          </div>
          <div class="form-group">
            <label for="newPassword">
              New Password
            </label>
            <input type="password" id="newPassword" class="form-control" [(ngModel)]="resetPassword.newPassword"
              name="newPassword" (ngModelChange)="fnCompare()">
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-success" [disabled]="!oldNewPasswordMatch">
              Reset
            </button>
            <p class="mb-0 ml-3 text-success d-inline-block" id="passwordMessage">
              {{message}}
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
