import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DataTablesModule } from 'angular-datatables';
import { StorageServiceModule } from 'angular-webstorage-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { UserComponent } from './iam/user/user.component';
import { RolesComponent } from './iam/roles/roles.component';
import { CreateRoleComponent } from './iam/roles/create-role/create-role.component';
import { HelpFloatBarComponent } from './help-float-bar/help-float-bar.component';
import { SidebarModule } from '@syncfusion/ej2-angular-navigations';
import { InitialProvidersDashboardComponent } from './providers/initial-providers-dashboard/initial-providers-dashboard.component';
import { GroupComponent } from './iam/group/group.component';
import { AddUserComponent } from './iam/add-user/add-user.component';
import { AddProviderComponent } from './providers/add-provider/add-provider.component';
import { JwtModule } from '@auth0/angular-jwt'
import { BasicAuthInterceptor } from './auth/auth.service';
import { SubgroupComponent } from './iam/group/subgroup/subgroup.component';
import { NgxPaginationModule } from "ngx-pagination";
import { ProfileComponent } from './profile/profile.component';
import { FaqComponent } from './faq/faq.component';
import { FilterModule } from './filter/filter.module';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
//import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
// import { RecaptchaComponent } from 'ng-recaptcha';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    UserComponent,
    RolesComponent,
    CreateRoleComponent,
    InitialProvidersDashboardComponent,
    AddProviderComponent,
    HelpFloatBarComponent,
    GroupComponent,
    SubgroupComponent,
    AddUserComponent,
    ProfileComponent,
    FaqComponent,
    ComingSoonComponent,
    //RecaptchaComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    StorageServiceModule,
    HttpClientModule,
    DataTablesModule,
    SidebarModule,
    FilterModule,
    NgxPaginationModule,
    // RecaptchaModule,
    // RecaptchaFormsModule
   ],

  exports: [
    NgxPaginationModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
  //schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}
JwtModule.forRoot({
  config: {
    tokenGetter: () => {
      return localStorage.getItem('optimatoken');
    },
    whitelistedDomains: ['192.168.0.44:4200'],
    blacklistedRoutes: ['192.168.0.44/optimaauth']
  }
})