<div class="below-navbar">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h3 class="below-nav-heading">
          Users
        </h3>
      </div>
    </div>
  </div>
</div>
<div class="middle-bar animated fadeInDown" id="addUser" [class.sticky]="sticky" #stickyMenu>
  <div class="container">
    <button type="button" style="font-weight:300" class="btn  btn-wide btn-create-green  create-surveyor btn-wide fw-300 mr-2"
      data-toggle="modal" data-target="#addEditUserModal" (click)="fnAddEditUserModal('')">
      <span class="fa fa-plus" style="margin-right:10px;"></span> New User
    </button>
    <!-- <button type="button" style="font-weight:300; border-radius: 0px;" class="btn  btn-wide btn-create-green  btn-wide fw-300"
      title="Upload User">
      <span class="fa fa-upload" style="margin-right:10px;"></span> Upload Users
    </button> -->
    <!-- <span class="s-blue" style="padding-left:20px;">Click here to create a new user</span> -->
    <span class="pull-right search-menu" style="position:relative;">
      <div class="input-group search-bar " style="position:absolute;right:40px;">
        <input type="text" class="form-control rfc" placeholder="Search" aria-describedby="sizing-addon2" name="searchUsr" [(ngModel)]="searchUsr"
          (ngModelChange)="fnSearchUserFtr()">
      </div>
      <i class="fa fa-search " style="font-size:20px;color:#798ca2;margin-top:5px;"></i>
    </span>
  </div>
</div>

<section class="mt-3">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="box">
          <div class="table-section">
            <div class="br-bottom">
              <h4 class="box-heading mt-0">
                User List|
                <span class="ft-12">
                  SORT BY :
                </span>
                <span class="fa fa-sort-alpha-asc sort-item"></span>
                <span class="fa fa-sort-alpha-desc sort-item"></span>
                <div class="float-right d-inline-block">
                  <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
              </h4>

              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th style="width: 135px;">Name</th>
                    <th>Email / Contact Number</th>
                    <th>Role</th>
                    <th>Team</th>
                    <!-- <th>Resources</th>
                    <th>Score</th> -->
                    <th>Created By</th>
                    <th>Date Added</th>
                  </tr>
                </thead>
                <tbody>
                  <!--*ngIf="users?.length != 0"-->
                  <tr *ngFor="let user of tmpArr | paginate: { itemsPerPage: 5, currentPage: p }; let i = index">
                    <td >
                      <span [class.disabled]="user.CREATED_BY == '0'|| user.USERID == email" class="fa fa-pencil action-items" data-toggle="modal" data-target="#addEditUserModal" data-placement="left" title="Edit User"
                        (click)="fnAddEditUserModal(user.ID)"></span>
                      <span [class.disabled]="user.CREATED_BY == '0' || user.USERID == email" class="fa fa-trash action-items" data-toggle="modal" data-target="#deleteUserModal" data-placement="left" title="Delete User"
                      (click)="fnDeleteUserModal(user.ID)"></span>
                      <span [class.disabled]="user.CREATED_BY == '0' || user.USERID == email" class="fa fa-users action-items" data-toggle="modal" data-target="#addGroupModal" data-placement="left" title="Assign Group"
                        (click)="fnGroupModal(user.ID)"></span>
                    </td>
                    <td style="width: 135px;"> {{user.NAME}}</td>
                    <td>{{user.USERID}}
                      <br />{{user.MOBILE}}</td>
                    <td class="table-survey-view">
                      <span>{{user.role}}</span>
                    </td>
                    <td class="table-survey-view" >
                      <span *ngFor="let gr of user.groups"  style="margin-bottom: 5px;line-height: 16px;">
                          <!--span *ngFor="let g of groups"-->
                            <!--span *ngIf="gr.DETAILS.ID == g.DETAILS.ID"-->
                              {{ gr.DETAILS.TITLE}}
                              <button  class="fa fa-times pointer in-blue"  (click)="fnDelGroupMap(gr.DETAILS.ID, user.ID)" 
                              style="font-size:12px; margin-left: 5px;" [disabled]="gr.DETAILS.ID == groups[0].DETAILS.ID || user.CREATED_BY == '0'|| user.USERID == email || gr.DETAILS.TITLE == 'root'">
                              </button>
                            <!--/span-->  
                          <!--/span-->
                        <!--i class="fa fa-times pointer in-blue" (click)="fnDelGroupMap(gr.DETAILS.ID, user.ID)"  style="font-size:12px; margin-left: 5px;"></i-->
                      </span>
                    </td>
                    <!-- <td>
                      <span class="count" (click)="fnGoToResources()">
                        {{i + 1}}
                      </span>
                    </td>
                    <td>
                      <span class="count">
                        {{i + 5}}
                      </span>
                    </td> -->
                    <td>{{ user.creator }}</td>
                    <td class="date">{{ user.createdDate }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--===================== Add Edit User Modal =====================-->

<div class="modal fade" id="addEditUserModal" tabindex="-1" role="dialog" aria-labelledby="addEditUserModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="action-box create-new-surveyor-action-box show" *ngIf="(!showLoader && successMessage == '' && errorMessage == '')"
        id="newSurveyorBlock">
        <h4 class="fw-700" style="margin-bottom:30px;" *ngIf="USER_ID == 0"> Create a New User
          <span class="pull-right">
            <span class="fa fa-times pointer in-blue" data-dismiss="modal" style="font-size:14px;"></span>
          </span>
        </h4>
        <h4 class="fw-700" style="margin-bottom:30px;" *ngIf="USER_ID!=0"> Update User
          <span class="pull-right">
            <span class="fa fa-times pointer in-blue" data-dismiss="modal" style="font-size:14px;"></span>
          </span>
        </h4>
        <form method="POST" (ngSubmit)="fnAddEditUser()" enctype="multipart/form-data">
          <div class="form-group">
            <label class="in-blue">User Name
              <span class="green">*</span>
            </label>
            <input type="text" class="form-control cfc ft-16 s-blue" id="name" name="name" [(ngModel)]="NAME" placeholder="User Name">
          </div>
          <div class="form-group">
            <label class="in-blue">Email
              <span class="green">*</span>
            </label>
            <input type="email" class="form-control cfc ft-16 s-blue" id="email" name="email" [(ngModel)]="EMAIL" placeholder="Email ID">
          </div>
          <div class="form-group">
            <label class="in-blue">Mobile
              <span class="green">*</span>
            </label>
            <input type="text" class="form-control cfc ft-16 s-blue" id="mobile" name="mobile" [(ngModel)]="MOBILE" placeholder="Contact Number">
          </div>
          <div class="form-group">
            <label class="in-blue">Role
              <span class="green">*</span>
            </label>
            <select class="form-control cfc ft-16 s-blue" id="roleId" name="roleId" [(ngModel)]="ROLE_ID">
              <option value="0" selected>Select Role...</option>
              <option *ngFor="let r of roles" value="{{r.ID}}">{{r.ROLE}}</option>
            </select>
          </div>
          <div class=" text-center" style="margin-top:30px;">
            <button type="submit" class="btn btn-default  btn-wide btn-lg btn-cool ft-16 btn-cool-green">SAVE</button>
          </div>
        </form>
        <!-- <p *ngIf="successMessage!==''" class="p-5 text-success text-center">{{successMessage}}</p>
        <p *ngIf="errorMessage!==''" class="p-5 text-failure text-center">{{errorMessage}}</p> -->
      </div>
      <div class="action-box create-new-surveyor-action-box show" *ngIf="showLoader">
        <!--=========== Show this body after button click ===========-->
        <div class="custom-loader">
          Please Wait
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>

      <div class="action-box create-new-surveyor-action-box show" *ngIf="(!showLoader && successMessage != '')">
        <!--=========== Show this body after API response is success ===========-->
        <div class="success-message">
          <p class="d-inline-block">
            {{successMessage}}
          </p>
          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        </div>
      </div>

      <div class="action-box create-new-surveyor-action-box show" *ngIf="(errorMessage != '' && !showLoader && successMessage == '')">
        <!--=========== Show this body after API response is success ===========-->
        <div class="success-message">
          <p class="d-inline-block text-danger">
            {{errorMessage}}
          </p>
          <!-- <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg> -->
        </div>
      </div>

    </div>
  </div>
</div>

<!--===================== Add Group Modal =====================-->
<div class="modal fade" *ngIf="isGroups" id="addGroupModal" tabindex="-1" role="dialog" aria-labelledby="addGroupModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="action-box create-new-surveyor-action-box show" id="newSurveyorBlock">
        <h4 class="fw-700" style="margin-bottom:30px;"> Add Team
          <span class="pull-right">
            <span class="fa fa-times pointer in-blue" data-dismiss="modal" style="font-size:14px;"></span>
          </span>
        </h4>
        <form action="" method="POST" (ngSubmit)="fnAddGroup()" enctype="multipart/form-data">
          <div class="modal-body">
            <app-subgroup parent="0" userId="0" subgroups={{groups[0].DETAILS.ID}} actType="choose" selGroupID={{selGroupID}}>

            </app-subgroup>
          </div>
          <div class=" text-center" style="margin-top:30px;">
            <button type="submit" class="btn btn-default  btn-wide btn-lg btn-cool ft-16 btn-cool-green">ASSIGN Team
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteUserModal" tabindex="-1" role="dialog" aria-labelledby="deleteUserModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="text-center">
        <div class="action-box">
          <form class="form-horizontal" name="form" (submit)="fnShowDeleteUserAlertModal(removed)" ngNativeValidate
          autocomplete="off" enctype="multipart/form-data">
            <h3 class="mr-top-0 in-blue box-heading  fw-300"> Confirm Delete</h3>
            <p> Do you want to delete the user
              <br />
              <span class="green" id="delete_survey_info"></span>
              ?</p>
            <p class="ft-12"> This Action can't be reversed</p>
            <button type="submit" class="btn btn-default btn-cool btn-cool-green mr-2" (click)="fnDeleteUser()">Delete</button>
            <button type="button" class="btn btn-default btn-cool" data-dismiss="modal">Cancel</button>
            <br />
            <p class="text-center mb-0 text-danger" *ngIf="errorMessage !== ''">
              {{errorMessage}}
            <p class="text-center mb-0 text-success" *ngIf="successMessage !== ''">
              {{successMessage}}
          </form>
        </div>
      </div>
    </div>
  </div>
</div>