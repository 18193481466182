import { Component, OnInit, Inject, HostListener, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../data.service';
import { GlobalService } from '../stores/global.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ViewportScroller, DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public navbarItems = [];

  constructor(
    private http: HttpClient,
    private route: Router,
    public data: DataService,
    public globalStore: GlobalService,
    private auth: AuthService,
    public viewPortScroller: ViewportScroller,
    @Inject(DOCUMENT) private document: Document,
    public authService: AuthService,
  ) { }


  @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
      let element = document.getElementById('navbar');
      //let sticky = element.offsetTop;
      let img = document.getElementById('logo');

      if (window.pageYOffset >=100) {
         console.log("100");
        element.classList.add('sticky');

      } else {
        element.classList.remove('sticky');
        console.log("not 100");

      }
    }

  fnCMSApi() {
    if (this.globalStore.SESSION_ID != "" && this.globalStore.SESSION_ID !== 'undefined') {
      let payload = { session_id: this.globalStore.SESSION_ID };
      this.http.post<DataTablesResponse>(
        this.data.loginURL + 'cms_user',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        if (resp.status_code === 'undefined') {
          // this.authService.logout();
        } else if (resp.status_code === 401) {
          // this.authService.logout();
        } else if (resp.status_code === 200) {
          if (resp.session_id != "") {
            let user = resp.data;
            let accessPermissions = JSON.parse(JSON.stringify(resp.data.accessPermissions));
            let pp = {};
            accessPermissions.map((ap) => {
              let keys = this.globalStore.fnGetKeys(ap);
              let k1 = {};
              keys.forEach(key => {
                if (key !== 'children') {
                  k1 = ap[key];
                } else if (key === 'children') {
                  ap['children'].map((ch) => {
                    let chKeys = this.globalStore.fnGetKeys(ch);
                    chKeys.forEach(chKey => {
                      if (chKey !== 'children') {
                        if (!k1) { k1 = {}; }
                        if (!k1[chKey]) { k1[chKey] = {}; }
                        k1[chKey] = ch[chKey];
                      } else if (chKey === 'children') {
                        ch['children'].map((sch) => {
                          let schKeys = this.globalStore.fnGetKeys(sch);
                          chKeys.forEach(schKey => {
                            if (schKey !== 'children') {
                              if (!k1) { k1 = {}; }
                              if (!k1[chKey]) { k1[chKey] = {}; }
                              if (!k1[chKey][schKey]) { k1[chKey][schKey] = {}; }
                              k1[chKey][schKey] = sch[schKey];
                            }
                          });
                        })
                      }
                    });
                  })
                }
                pp[key] = k1;
              });
            })

            user.accessPermissions = pp;
            this.globalStore.USER = user;
          }
        }
      }, error => {
      });
    } else {
    }
  }

  fnLoadNavbarItems() {
    //this.getUser();
    this.http.post<any>(this.data.loginURL + 'currentUser', null).subscribe(resp => {
      //alert(resp.data[0].SUPER_ADMIN)
      this.userName = resp.data[0].NAME;

      sessionStorage.setItem('name', resp.data[0].NAME);
      sessionStorage.setItem('userId', resp.data[0].USERID);
      sessionStorage.setItem('mobile', resp.data[0].MOBILE);
      sessionStorage.setItem('createdby', resp.data[0].CREATED_BY);
      sessionStorage.setItem('client', resp.data[1].Organization);
      sessionStorage.setItem('super_admin', resp.data[0].SUPER_ADMIN);
      if (sessionStorage.getItem('createdby') == "0"){
        if (sessionStorage.getItem('super_admin') == "yes"){
          this.navbarItems = [
            { item: "Dashboard", path: "dashboard" },
            {
                item: "Cloud Optima", path: "", children: [
                { item: "Providers", path: "providers" },
                { item: "Resources", path: "resources/resources-view" },
                { item: "Storage", path: "resources/resources-view/storage"},
                { item: "RDS", path: "resources/resources-view/rds"}
              ]
            },
            {
              item: "IAM", path: "", children: [
                { item: "Users", path: "users", icon: "fa fa-users" },
                { item: "Roles", path: "roles", icon: "fa fa-users" },
                { item: "Team", path: "groups", icon: "fa fa-group" }
              ]
            },
            { item: "Blog", path: "blog-admin" }
          ]
        } else {
          this.navbarItems = [
            { item: "Dashboard", path: "dashboard" },
            {
                item: "Cloud Optima", path: "", children: [
                { item: "Providers", path: "providers" },
                { item: "Resources", path: "resources/resources-view" },
                { item: "Storage", path: "resources/resources-view/storage"},
                { item: "RDS", path: "resources/resources-view/rds"}
              ]
            },
            {
              item: "IAM", path: "", children: [
                { item: "Users", path: "users", icon: "fa fa-users" },
                { item: "Roles", path: "roles", icon: "fa fa-users" },
                { item: "Team", path: "groups", icon: "fa fa-group" }
              ]
            }
          ]
    }
    } else {

      this.navbarItems = [
        { item: "Dashboard", path: "dashboard" },
        {
            item: "Cloud Optima", path: "", children: [
            { item: "Providers", path: "providers" },
            { item: "Resources", path: "resources/resources-view" },
            { item: "Storage", path: "resources/resources-view/storage"},
            { item: "RDS", path: "resources/resources-view/rds"}
          ]
        }
      ]
    }
  })

    //this.fnCMSApi(); // -> Will call 'cms_user' from Another server
    this.fnNavigateTo('','')
  }

  logout() {
    this.navbarItems = []
    this.auth.logout()
  }

  getProfile() {
    this.route.navigate(['/profile']);
  }

  userName: any;
  public getUser() {
    this.http.post<any>(this.data.loginURL + 'currentUser', null).subscribe(resp => {

      this.userName = resp.data[0].NAME;
      sessionStorage.setItem('name', resp.data[0].NAME);
      sessionStorage.setItem('userId', resp.data[0].USERID);
      sessionStorage.setItem('mobile', resp.data[0].MOBILE);
      sessionStorage.setItem('createdby', resp.data[0].CREATED_BY);
      sessionStorage.setItem('client', resp.data[1].Organization);

    })
  }

  public getFaq() {
    this.route.navigate(['/faq']);
  }

  ngOnInit() {
    if (this.globalStore.IS_LOGGED_IN)
      this.fnLoadNavbarItems();
  }

  fnNavigateTo(path: any, item: any) {
    if (path == '') {
      this.data.activeMenu = 'dashboard'
      this.route.navigate(['dashboard']);
    } else if (path == 'resources/resources-view') {
      this.data.providerTitle = 'All'
      this.data.currentNavPath = 'resources-view';
      this.data.activeSubMenu = 'resources-view';
      this.route.navigate([path]);
      this.data.activeMenu = path;
    } else {
      this.data.activeMenu = path;
      this.route.navigate([path])
    }
    this.data.comingSoon = item
  }

}

export class DataTablesResponse {
  status_code: any;
  data: any;
  session_id: any;
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
