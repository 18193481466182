<!-- <p>user works!</p> -->
<div class="below-navbar">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h3 class="below-nav-heading">
          Team
        </h3>
      </div>
    </div>
  </div>
</div>


<div class="middle-bar animated fadeInDown" id="addGroup" [class.sticky]="sticky" #stickyMenu>
  <div class="container">
    <button type="button" style="font-weight:300; border-radius: 0px;"
      class="btn  btn-wide btn-create-green  btn-wide fw-300 mr-2" title="Add Sub-Group"
      (click)="fnOpenAddGroupPopUp()">
      <span class="fa fa-plus" style="margin-right:10px;"></span> New Team
    </button>
    <span class="s-blue" style="padding-left:20px;">Click here to create a new Team</span>
    <span class="pull-right search-menu" style="position:relative;">
      <div class="input-group search-bar " style="position:absolute;right:40px;">
        <input type="text" class="form-control rfc" placeholder="Search" aria-describedby="sizing-addon2">
      </div>
      <i class="fa fa-search " style="font-size:20px;color:#798ca2;margin-top:5px;"></i>
    </span>
  </div>
</div>

<section class="mt-3">
  <div class="container position-relative">
    <div class="row">
      <div class="guide white_arrow_box new-q-guide"
        style="width: 270px;position: absolute;top: 0px;left: 0px;z-index: 3;border: thick;background-color: white;height: 152px;"
        *ngIf="showGuideBox">
        <i class="fa fa-times" style="    position: absolute;
      right: 22px;
      top: 18px;"></i>
        <h3 class="fw-700 black  mr-top-0 ft-18">
          Click to add new Team
        </h3>
        <p class="ft-13">
          Click the above button to get started
        </p>
        <div class="close-guide pointer">
          <span class="glyphicon glyphicon-remove s-grey"></span>
        </div>
      </div>
      <!-- {{groups|json}} -->
      <div class="col-12">
        <div class="box">
          <div class="table-section">
            <div class="br-bottom">
              <h4 class="box-heading mt-0">Team List</h4>
              <app-subgroup *ngIf="groups && groups[0] && groups[0].DETAILS && groups[0].DETAILS.ID" parent='0' subgroups={{groups[0].DETAILS.ID}}></app-subgroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="container">
    <div class="row">
      <div class="guide white_arrow_box new-q-guide"
        style="width: 270px;position: absolute;top: 188px;left: 28px;z-index: 3;border: thick;background-color: white;height: 152px;">
        <h3 class="fw-700 black  mr-top-0 ft-18"
          style="position: relative;top: 25px;left: 16px;font-size: 18px;font-weight:600">
          Create New Team
        </h3>
        <p class="ft-13" style="position: relative;top: 26px;left: 16px;font-size: 18px;color : #8d9fa4 ;">
          Click the above button to get started
        </p>
        <div class="close-guide pointer">
          <span class="glyphicon glyphicon-remove s-grey"></span>
        </div>
      </div>
    </div>
  </div> -->
</section>

<!--==================== Add Group Pop Up ====================-->

<div *ngIf="addGroupPopup">
  <div class="overlay">
    <div class="popup-box">
      <div class="action-box create-new-surveyor-action-box show" id="newSurveyorBlock">
        <h4 class="fw-700" style="margin-bottom:30px;" *ngIf="addEditGroup.ID==0"> Create a New Team <span
            class="pull-right">
            <span class="fa fa-times pointer in-blue" (click)="fnCloseAddGroupPopup()"
              style="font-size:14px;"></span></span>
        </h4>
        <h4 class="fw-700" style="margin-bottom:30px;" *ngIf="addEditGroup.ID!=0"> Update Team <span class="pull-right">
            <span class="fa fa-times pointer in-blue" (click)="fnCloseAddGroupPopup()"></span></span>
        </h4>
        <form *ngIf="successMessage===''" method="POST" (ngSubmit)="fnAddSubGroup()" autocomplete="off"
          enctype="multipart/form-data">
          <div class="form-group">
            <label class="in-blue">Title <span class="blue">*</span></label>
            <input type="text" class="form-control cfc ft-16 s-blue" id="name" name="name"
              [(ngModel)]="addEditGroup.TITLE" placeholder="Team Title">
          </div>
          <div class=" text-center" style="margin-top:30px;">
            <button type="submit" class="btn btn-default  btn-wide btn-lg btn-cool ft-16 btn-cool-green">SAVE </button>
          </div>
        </form>
        <p class="text-center mb-0 text-danger" *ngIf="errorMessage !== ''">
          {{errorMessage}}
        <p class="text-center mb-0 text-success" *ngIf="successMessage !== ''">
          {{successMessage}}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="deleteGroupModal" tabindex="-1" role="dialog" aria-labelledby="deleteGroupModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="text-center">
        <div class="action-box">
          <form class="form-horizontal" name="form" (submit)="fnDeleteSubGroup()" ngNativeValidate>
            <h3 class="mr-top-0 in-blue box-heading  fw-300"> Confirm Delete</h3>
            <p> Do you want to delete the team (this action will delete subgroups as well) <br />
              <span class="blue" id="delete_survey_info"></span> ?
            </p>
            <p class="ft-12"> This Action can't be reversed</p>
            <button type="submit" class="btn btn-default btn-cool btn-cool-green mr-2"
              (click)="fnDeleteSubGroup()">Delete</button>
            <button type="button" class="btn btn-default btn-cool" data-dismiss="modal">Cancel</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
