<div *ngIf="globalStore.IS_LOGGED_IN">
  <app-navbar></app-navbar>
  <app-help-float-bar></app-help-float-bar>
  <div *ngIf="!globalStore.showSpinner">
    <router-outlet></router-outlet>
  </div>
</div>

<div *ngIf="!globalStore.IS_LOGGED_IN">
  <!-- <app-navbar *ngIf="globalStore.showWebPageNav"></app-navbar> -->
  <router-outlet></router-outlet>
</div>

<div class="modal fade" id="unauthorizedModal" tabindex="-1" role="dialog" aria-labelledby="unauthorizedModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="unauthorizedModalLabel">Authorization Fail</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="fnCloseModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Authorization Message: -->.
        {{globalStore.URL}}
        <br><br>

        <div class="col-xs-12 text-center form-group">
          <button class="btn btn-primary" type="submit" (click)="fnCloseModal()">
            Close
          </button>
      </div>
      </div>
    </div>
  </div>
</div>
