import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'angular-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private router: Router
  ) { }

  public URL:any;
  public LOADING: boolean = true;
  public IS_LOGGED_IN: boolean = this.check();
  public SESSION_ID: string = localStorage.getItem('SESSION_ID') ? localStorage.getItem('SESSION_ID') : "";
  public activeMenu: string = "dashboard";
  public USER: any = {}
  public accessPermissions: any = {};
  showWebPageNav: boolean = false
  showSpinner: boolean = false;
  public check(): boolean {
    // if (localStorage.getItem('isLoggedIn') === 'true') {
    if (localStorage.getItem('optimatoken') != "" || localStorage.getItem('optimatoken') != null) {
      return true;
    } else {
      return false;
    }
  }

  public fnGetKeys(obj: any = {}) {
    return Object.keys(obj);
  }

  public fnSetLocalVariable(key = "", value) {
    if (key !== "" && value !== "") {
      this.storage.set(key, value);
    }
  }
  public fnGetLocalVariable(key = "") {
    if (key) {
      return this.storage.get(key);
    } else {
      return;
    }
  }
  public fnRemoveLocalVariable(key = "") {
    if (key) {
      this.storage.remove(key);
    }
  }
}
