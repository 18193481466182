import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/stores/global.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  public addUser: any = {};
  //public title:any;
  public addEditPermissions: any = [];
  public start = 0;
  public limit = 10;
  public loading: boolean = false;
  public users: any = [];
  public roles: any = [];
  public addGroup: any = {};
  public subGroup: any = {};
  public successMessage = "";

  groupArr: any = [];
  group: any = { "id": "", "title": "", "groupArr": [] }
  //list:any=[{'id':""}]
  directories: any = [];
  expanded: boolean = false;

  constructor(
    public globalStore: GlobalService,
    public data: DataService,
    private http: HttpClient,
    private route: Router,
  ) { }

  nestedUL: any;

  fnPush(g: any, id: any) {
    g.push({ "id": this.groupArr.length + 1, "title": this.addGroup.title, "groupArr": [] })
    // g.push({"id":this.groupArr.length+1, "title":"Group-"+parseFloat(this.groupArr.length +1), "groupArr":[this.addGroup.title]})
    //  this.deleteRoleModal.hide();
  }

  fnNestedUL() {
    this.nestedUL = "<li *ngFor=\"let g of gr.groupArr; let j = index\"><div class=\"col-1\"><button class=\"btn btn-primary mr-0 mt-2\"    data-toggle=\"modal\" data-target=\"#deleteRoleModal\"     type=\"button\" (click)=\"fnPush(gr.groupArr, 1)\"><i class=\"fa fa-plus\"></i></button></div></li>";
  }

  /*
  <ul>
              <li *ngFor="let g of gr.groupArr; let j = index"> 
                <div class="col-1">
                  <button class="btn btn-primary mr-0 mt-2" type="button" (click)="fnPush(gr.groupArr, 1)">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>    
                <div class="row" style="margin-left: 0px; margin-right: 0px;">
                  <div class="col-9">
                    <!-- <input class="form-control mt-1" type="text" name="list" [(ngModel)]="addUser.group"
                    required /> -->
                    {{g.title}}
                  </div>
                  <div class="col-1">
                    <button class="btn btn-primary mr-0 mt-2" type="button" (click)="fnPushToSecond(1)">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                  <div class="col-1">
                    <input type="button" class="btn btn-primary waves-light mt-2" value="Sub">
                  </div>
                </div>
              </li>
            </ul>
          */

  fnNotpush() {
    this.route.navigate(['/add-user']);
  }

  fnPushToSecond(id: any) {
    this.groupArr.list.push(id);
  }

  addSubGroup(g) {
    var sg = { "id": g.length + 1, "title": this.subGroup.title, "groupArr": [] };
    // var sg = {"id":g.length+1, "title":g.length+1, "groupArr":[]};
    g.groupArr.push(sg); // this.groupArr.push(sg); //
  }

  groupId: any;

  fnDeleteGroup(id: any): void {
    // let payload: any = {};
    // // payload.session_id = this.globalStore.SESSION_ID;
    // payload.role_id = this.role.id;
    // this.http
    //   .post<DataTablesResponse>(
    //     this.api.loginURL + 'cms_deleterole',
    //     payload, { headers: this.api.headers() }
    //   ).subscribe(resp => {
    //     this.roles = this.roles.filter((role) => role.id !== resp.data.role_id);
    //     setTimeout(() => {
    //       // this.deleteRoleModal.hide();
    //       $("#deleteRoleModal").hide();
    //     }, 500);
    //   });
    this.groupId = id;
  }

  fnEditGroup(id: any) {
    this.groupId = id;
    //  this.addGroup.title = this.groupArr[this.groupId-1].title;
  }

  fnEditConfirm() {
    this.groupArr[this.groupId - 1].title = this.addGroup.title;
  }


  fnConfirmDeleteGroup() {
    this.groupArr.slice(this.groupId - 1, 1)
  }

  fnToggle() {
    this.expanded = true;
  }

  fnAddEditUser(): void {
    this.loading = true;
    let payload: any = {};
    payload.id = this.addUser.id;
    payload.name = this.addUser.name;
    payload.email = this.addUser.email;
    payload.mobile = this.addUser.mobile;
    payload.roleId = this.addUser.roleId;
    //payload.reportToWhom = this.addUser.reportToWhom;
    //payload.accessPermissions = this.addEditPermissions.dataJson;
    payload.isActive = this.addUser.isActive;
    payload.session_id = this.globalStore.SESSION_ID;
    this.http
      .post<DataTablesResponse>(
        this.data.loginURL + 'cms_addedituser',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        if (this.addUser.id > 0) {
          this.users.map((user: any) => {
            if (user.id === this.addUser.id) { user = resp.data; }
            if (this.globalStore.USER.id === resp.data.id) { this.globalStore.USER = resp.data; }
            this.successMessage = "User info saved successfully.";
          });
        } else {
          this.users = [...[resp.data], ...this.users];
          this.successMessage = "User has been created successfully, auto generated password has been sent to the registered email id.";
        }
        this.loading = false;

        setTimeout(() => {
          //  this.fnCloseAddEditUserModal();
          // this.fnCloseEditAccessPermissionsModal();
        }, 3000);
      }, error => {
        this.loading = false;

        setTimeout(() => {
          // this.fnCloseAddEditUserModal();
          // this.fnCloseEditAccessPermissionsModal();
        }, 3000);
      });
  }


  fnLoadUsers(): void {
    const that = this;
    this.loading = true;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      scrollX: false,
      info: false,
      responsive: false,

      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters = { session_id: this.globalStore.SESSION_ID };
        that.start = dataTablesParameters.start;
        that.limit = dataTablesParameters.length;
        that.http
          .post<DataTablesResponse>(
            that.data.loginURL + 'cms_users',
            dataTablesParameters, { headers: this.data.headers() }
          ).subscribe(resp => {
            that.users = resp.data;
            this.loading = false;
            // let accessPermissions = JSON.parse(JSON.stringify(resp.data.accessPermissions));

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });

          });
      },
      // columns: [{ data: 'id' }, { data: 'name' }, { data: 'email' }, {data: 'mobile'}, {data: 'role'}, {data: 'creator'}, {data: 'isActive'}, {data: 'createdDate'}, {data: null}]
      columns: [{ data: 'id' }, { data: 'name' }, { data: 'role' }, { data: 'createdDate' }, { data: null }]
    };
  }


  fnGetUsers(): void {
    this.loading = true;
    let payload: any = {};
    payload.start = this.start;
    payload.length = this.limit;
    payload.session_id = this.globalStore.SESSION_ID;
    this.http
      .post<DataTablesResponse>(
        this.data.loginURL + 'cms_users',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        this.users = resp.data;
        this.loading = false;
      });
  }

  fnGetUserRoles(): void {
    let payload = { session_id: this.globalStore.SESSION_ID };
    this.http
      .post<DataTablesResponse>(
        this.data.loginURL + 'cms_roles',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        this.roles = resp.data;
      });
  }

  fnNavigateToUser() {
    this.route.navigate(['/users']);
  }

  ngOnInit() {
    this.groupArr.push({ "id": "1", "title": "Group-1", "groupArr": [] });
    this.groupArr.push({ "id": "2", "title": "Group-2", "groupArr": [] });
    this.groupArr.push({ "id": "3", "title": "Group-3", "groupArr": [] });
    this.fnNestedUL();
    this.fnLoadUsers();
    this.fnGetUserRoles();
    this.fnGetUsers();
  }


}

export class DataTablesResponse {
  status_code: any;
  data: any;
  session_id: any;
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
