import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { GlobalService } from 'src/app/stores/global.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/data.service';
import { CreateRoleComponent } from './create-role/create-role.component'

import { map } from 'rxjs/operators';
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  @ViewChild(CreateRoleComponent, { static: false }) public createRoleComponent;

  constructor(public globalStore: GlobalService, private http: HttpClient, private api: DataService) { }

  public dtOptions: DataTables.Settings = {};
  public roles = [];
  public loading: boolean = false;
  public start = 0;
  public limit = 10;
  public addEditRole: any = {};
  public loaders: any = { role_id: 0, activateLoader: false, editLoader: false };

  private roleStruc: any = {
    title: "",
    description: "",
    dataJson: [],
  };
  public confirmationMessage: string;
  public role: any;
  public showRoles: boolean = false;
  public deleteRoleOptions = ['Suspend', 'Terminate'];

  fnLoadRoles(): void {
    this.loading = true;
    this.showRoles = true;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      info: false,
      ajax: (payload: any, callback) => {
        payload.session_id = this.globalStore.SESSION_ID;
        this.start = payload.start;
        this.limit = payload.length;
        this.http
          .post<DataTablesResponse>(
            this.api.loginURL + 'cms_roles',
            payload, { headers: this.api.headers() }
          ).subscribe(resp => {
            this.roles = resp.data;
            this.loading = false;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          }, error => {
            this.loading = false;
          });
      },
      columns: [{ data: 'id' }, { data: 'title' }, { data: 'creator' }, { data: 'isActive' }, { data: 'updatedOn' }, { data: 'actions' }]
    };
  }

  fnShowDeleteRoleModal(id): void {

    if (id !== "") {
      this.role = this.roles.filter((role) => role.id === id)[0];
      this.confirmationMessage = "Do you want to delete this role?";
      // this.deleteRoleModal.show();
    }
  }

  // fnOpenEditRoleModal(id: any) {
  //   this.createRoleComponent.fnOpenAddEditRoleModal(id);
  // }

  fnCloseDelteRoleModal(id: any) {
    // this.deleteRoleModal.hide();
  }

  fnDoNotDeleteRole(): void {
    // this.deleteRoleModal.hide();
  }

  fnDeleteRole(): void {
    let payload: any = {};
    payload.session_id = this.globalStore.SESSION_ID;
    payload.role_id = this.role.id;
    this.http
      .post<DataTablesResponse>(
        this.api.loginURL + 'cms_deleterole',
        payload, { headers: this.api.headers() }
      ).subscribe(resp => {
        this.roles = this.roles.filter((role) => role.id !== resp.data.role_id);
        setTimeout(() => {
          // this.deleteRoleModal.hide();
          $("#deleteRoleModal").hide();
        }, 500);
      });
  }

  fnRefreshRoles() {
    this.showRoles = false;
    setTimeout(() => {
      this.showRoles = true;
    }, 5);
  }



  fnAddEditRole(): void {
    this.loading = true;
    let payload: any = {};
    payload = this.addEditRole;
    payload.dataJson = JSON.stringify(this.addEditRole.dataJson);
    payload.session_id = this.globalStore.SESSION_ID;
    this.http
      .post<DataTablesResponse>(
        this.api.loginURL + 'cms_addeditrole',
        payload, { headers: this.api.headers() }
      ).subscribe(resp => { }, error => {
      });
  }

  fnActivateRole(id, isActive) {
    this.loaders.id = id;
    this.loaders.activateLoader = true;
    let payload: any = {};
    payload.id = id;
    payload.isActive = isActive;
    payload.session_id = this.globalStore.SESSION_ID;
    this.http
      .post<DataTablesResponse>(
        this.api.loginURL + 'cms_addeditrole',
        payload, { headers: this.api.headers() }
      ).subscribe(resp => {
        this.roles = this.roles.map((role) => {
          if (role.id == resp.data.id) {
            role.isActive = resp.data.isActive;
          }
          return role;
        });
        this.loaders.activateLoader = false;
      }, error => {
        this.loaders.activateLoader = false;
      });
  }

  fnOpenEditRoleModal(id: any) {
  }

  // New code
  sticky: boolean = false;
  elementPosition: any;
  menuPosition: any;

  public successMessage = "";



  selectedRoleIndex: any = 0

  roleList: any = []

  accessPermission: any = []

  ap: any = [];

  selectRole: any = [];
  viewOnly: boolean = true;
  newRoleTitle: any = '';



  async fnGetAccess() {
    await this.http.get<any>(this.api.loginURL + 'access').subscribe(resp => {
      resp.data.data = resp.data.data.map((ap: any) => {
        ap.permissions = ap.permissions.map((per: any) => {
          per.permission.add = (per.permission.add == 1 || per.permission.add == '1') ? true : false;
          per.permission.edit = (per.permission.edit == 1 || per.permission.edit == '1') ? true : false;
          per.permission.view = (per.permission.view == 1 || per.permission.view == '1') ? true : false;
          per.permission.delete = (per.permission.delete == 1 || per.permission.view == '1') ? true : false;
          return per;
        });
        return ap;
      });
      this.accessPermission = resp.data.data
    })
  }

  fnShowHideAddPermission() {
    this.viewOnly = !this.viewOnly;
  }

  async fnGetRole(id: any, index: any) {
    this.disableUpdate = true

    let payload: any = {}
    if (id == '') {
      payload.role_id = '0'
    } else {
      payload.role_id = id
    }
    await this.http.post<DataTablesResponse>(this.api.loginURL + 'roles', payload, { headers: this.api.headers() }).subscribe(resp => {
      if (this.roleList.length == 0) {
        this.roleList = resp.data;
      }
      if (resp.data.length == undefined) {
        this.selectRole = []
        this.selectRole = resp.data.ACCESS_PERMISSION
      }
    });
    this.selectedRoleIndex = index;

  }

  fnAddNewRole() {
    this.viewOnly = !this.viewOnly;
  }

  roleJson: any = {
    "scope": "",
    "permissions": [
      {
        "type": "",
        "permission": {
          "add": "", "edit": "", "delete": "", "view": ""
        }
      }
    ]
  }

  permission: any = {}

  appliedRole: any = [];

  checkboxSelected(event: any, scope: any, permissionType: any, permissionValue: any) {
    if (event == true) {
      event = '1'
    } else {
      event = '0'
    }
    if (this.roleJson.scope == '') {
      this.roleJson.scope = scope;
      for (let i in this.roleJson[0].permissions) {
        if (this.roleJson[0].permissions[i].type == '') {
          this.roleJson[0].permissions[i].type = permissionType
        }
        if (permissionValue == 'add') {

        }
      }
    }
  }

  payloadAccessPermissions: any = [];

  fnSaveRole() {
    this.payloadAccessPermissions = this.accessPermission
    let payload: any = {};
    payload.ROLE = this.newRoleTitle;
    let accPer = JSON.parse(JSON.stringify(this.accessPermission));
    accPer = accPer.map((ap: any) => {
      ap.permissions = ap.permissions.map((per: any) => {
        per.permission.add = (per.permission.add == true) ? '1' : '0'
        per.permission.edit = (per.permission.edit == true) ? '1' : '0'
        per.permission.delete = (per.permission.delete == true) ? '1' : '0'
        per.permission.view = (per.permission.view == true) ? '1' : '0'
        return per;
      });
      return ap;
    });
    payload.ACCESS_PERMISSION = accPer
    this.http.post<any>(this.api.loginURL + 'addRole', payload).subscribe(resp => {
      this.roleList.push(resp.data[0])
      this.newRoleAdded.splice(this.newRoleAdded.length - 1, 1)
      this.newRoleTitle = "New Role"
      this.selectedRoleIndex = this.roleList.length
      this.viewOnly = true
    })
  }

  newRoleAdded = []
  disableUpdate: boolean = true;

  fnAddNewRoleTitle() {
    this.newRoleAdded.push({ "ROLE": this.newRoleTitle })
    this.selectedRoleIndex = 'new'
  }

  fnRemoveRoleTitle() {
    this.newRoleAdded.splice(this.newRoleAdded.length - 1, 1)
  }

  fnChangeAccessPermission(index: any, scope: any, type: any, ap: any) {
    this.disableUpdate = false;
    for (let i in this.selectRole) {
      if (scope == this.selectRole[i].scope) {
        if (type == 'add') {
          if (ap == 1) {
            this.selectRole[i].permissions[index].permission.add = 0
          } else {
            this.selectRole[i].permissions[index].permission.add = 1
          }
        } else if (type == 'edit') {
          if (ap == 1) {
            this.selectRole[i].permissions[index].permission.edit = 0
          } else {
            this.selectRole[i].permissions[index].permission.edit = 1
          }
        } else if (type == 'delete') {
          if (ap == 1) {
            this.selectRole[i].permissions[index].permission.delete = 0
          } else {
            this.selectRole[i].permissions[index].permission.delete = 1
          }
        } else {
          if (ap == 1) {
            this.selectRole[i].permissions[index].permission.view = 0
          } else {
            this.selectRole[i].permissions[index].permission.view = 1
          }
        }
      }
    }
  }

  fnUpdate() {

  }

  ngOnInit() {
    this.selectedRoleIndex = '0';
    this.fnGetRole('', '0');
    this.fnGetAccess()
    //this.fnGetRole('1', '0');
    
  }

}

export class DataTablesResponse {
  data: any;
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


