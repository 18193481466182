import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './iam/user/user.component';
import { RolesComponent } from './iam/roles/roles.component';
import { GroupComponent } from './iam/group/group.component';
import { InitialProvidersDashboardComponent } from './providers/initial-providers-dashboard/initial-providers-dashboard.component';
import { AddUserComponent } from './iam/add-user/add-user.component';
import { AddProviderComponent } from './providers/add-provider/add-provider.component';
import { FilterModule } from './filter/filter.module';
import { ProfileComponent } from './profile/profile.component';
import { FaqComponent } from './faq/faq.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';



const routes: Routes = [
  { path: '', loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule) },
  
  { path: 'login', loadChildren: () => import('./auth/login/login.component').then(mod => mod.LoginComponent) },
  { path: 'signup', loadChildren: () => import('./auth/signup/signup.component').then(mod => mod.SignupComponent) },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule) },
  { path: 'cost-optimisation', loadChildren: () => import('./cost-optimisation/cost-optimisation.module').then(mod => mod.CostOptimisationModule) },
  { path: 'providers', loadChildren: () => import('./providers/providers.module').then(mod => mod.ProvidersModule) }, // -> Change it later to auth
  { path: 'resources', loadChildren: () => import('./resources-import/resources-import.module').then(mod => mod.ResourcesImportModule) },
  { path: 'select-provider', component: InitialProvidersDashboardComponent },
  { path: 'add-provider', component: AddProviderComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'users', component: UserComponent },
  { path: 'roles', component: RolesComponent },
  { path: 'groups', component: GroupComponent },
  { path: 'add-user', component: AddUserComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'blog-admin', loadChildren: () => import('./blog-admin/blog-admin.module').then(m => m.BlogAdminModule) },
  { path: '**', component: ComingSoonComponent },
  
 // { path: 'optima-resources', loadChildren: () => import('./resources-import/optima-resources/optima-resources.module').then(m => m.OptimaResourcesModule) }
  // { path: 'resources/overview', component: OverviewComponent }
];

@NgModule({
  //imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
