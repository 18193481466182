<nav class="navbar navbar-expand-sm" id="navbar" (scroll)="onWindowScroll($event);" style="background-color: #24a0ed;">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
    aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
    <a class="navbar-brand" class="mr-3" (click)="fnNavigateTo('dashboard','')">
      <img src="./assets/images/windmill1.png" class="brand-img" style="height:60px;">
    </a>
    <ul class="navbar-nav mr-auto ">
      <li class="nav-items" *ngFor="let nav of navbarItems"
        [ngClass]="{'dropdown': nav.item === 'IAM' || 'Resources'}">
        <span *ngIf="!nav.children">
          <a (click)="fnNavigateTo(nav.path, nav.item)" [ngClass]="{'active':nav.path == data.activeMenu}" class="nav-link">
            {{ nav.item }}
          </a>
        </span>
        <span *ngIf="nav.children">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            id="iamDropdownMenuLink">
            {{ nav.item }}
          </a>
          <span class="dropdown-menu up p-0" aria-labelledby="iamDropdownMenuLink">
              <a class="dropdown-item" *ngFor="let childMenu of nav.children" (click)="fnNavigateTo(childMenu.path, childMenu.item)" [ngClass]="{'active':nav.path == data.activeMenu}">
              <span class="provider-onhover mr-2">
                <i class="{{childMenu.icon}}"></i> {{childMenu.item}}
              </span>
            </a>
          </span>
        </span>
      </li>
    </ul>
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item">
        <a class="nav-link" (click)="getFaq()">
          Terms & Conditions
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropbtn">
          {{userName}} <span class="ml-2 up fa fa-caret-down"></span>
        </a>
        <div class="dropdown-content">
          <a (click)="getProfile()">
            <i class="fa fa-user"></i> My Profile</a>
          <a (click)="logout()">
            <i class="fa fa-sign-out"></i> Logout
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
