import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  constructor(public data: DataService, public route: Router) { }

  fnNavigate(path: any) {
    this.data.providerTitle = 'All'
    this.data.currentNavPath = 'resources-view';
    this.route.navigate([path]);
    this.data.activeMenu = path;
  }

  ngOnInit() {
  }

}
