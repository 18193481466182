<div class="below-navbar">
    <div class="container-1">
        <div class="row">
            <div class="col-md-6 px-5" style="height: 200px;">
                <h3 class="below-nav-heading">
                    Terms and Conditions
                </h3>
            </div>
        </div>
    </div>
</div>

<div class=" container-2  mx-5 " style="   position:absolute">
    <div class="row my-6">
        <div class="col-md-3 col-sm-12 pr-2 my-5 ml-5 pl-3">
            <div class="dez pr-3 text-right">
                <ul>
                    <li class="active green"> General </li>
                    <li class="active green">Introduction</li>
                    <li class="active green">Signing Up</li>
                    <li class="active green">Windmill services detail</li>
                    <li class="active green">Restriction of Windmill services</li>
                    <li class="active green">Windmill services Terms</li>
                    <li class="active green">Limitation of Liability</li> 
                    <li class="active green">Ownership Of Intellectual Property</li>
                </ul>
            </div>


        </div>
        <!-- card row -->
        <div class="col-md-6 col-sm-12 my-5 px-5">
            <h2 > General</h2>
            <p>Please read the following terms and conditions very carefully. by accessing or otherwise availing the 
               services or by using the platform, you accept and agree to all of the terms and conditions contained 
               herein. These terms constitutes a legally binding agreement between you and the company.
            </p><br>
            <p>While using the services, you may be subject to any posted guidelines or rules applicable for the use 
               of the platform. All such guidelines or rules are hereby incorporated by reference into these terms.
             </p><br>
             <p>By using the platform, our services or website, you de-facto agree to the following terms.
                If you do not agree to these terms, do not use the platform, our services or website.
                You also represent that you have read and understood the provisions of these terms.
              </p><br>
              <p><b>“Company”</b> or <b>“we”</b> or <b>“our”</b> shall mean CloudWindmill Solutions LLP.</p>
              <p><b>“Services”</b> shall mean Platform as a service offered by the Company that enables the Users to automate
                and manage their public cloud cost, hosted application, set up the infrastructure, and deploy the same with use
                of the Platform. The details of the Services are more specifically set out in titled ‘Services Details’ of these Terms of Service. The Users (defined below) can subscribe and avail the Services, provided by the Company, by accessing and registering on the Website.</p>
              <p><b>“User Account(s)”</b> shall mean the User’s registered account maintained on the Website.</p>
              <p><b>“User/Users”</b> shall mean the registered individuals/Companies/Entities who avail the Services, 
                use of the Platform through Website. </p>
              <p><b>“Website”</b> shall mean website (http://cloudwindmill.io) developed and owned by the Company, to enable the Users to avail 
                 use of the Platform and the Services offered by the Company through Website.</p>
                 <p><b>“You” </b>or <b>“Your”</b> or “Yourself” shall mean the Users as per the context.</p><br>
            <h2>Inroduction to CloudWindmill Solutions</h2>
            <p>Welcome to our CloudWindmill.io. If You continue to browse and use the Website, You are agreeing to comply with
               and be bound by these terms and conditions (“Terms of Service”), which together with our Privacy Policy 
               (“Privacy Policy”) govern the Company’s relationship with You. If You disagree with any part of these Terms 
               of Service or Privacy Policy, You may not use the Website in entirety. The Services are subject to the 
               limitation to these Terms of Service and the Privacy Policy. Company may discontinue or revise any or all 
               aspects of the Services at any time, at its sole discretion. </p>
            <p>You shall abide by these Terms of Service. These Terms of Service is an electronic record which is generated 
                by a computer system and does not require any physical or digital signatures. </p> <br>
            <h2>Signing Up</h2>
            <p>In order to subscribe for the Services, You will be required to register and create an account 
               with the User ID and the password (collectively “User Account”). The Company would complete the 
               verification of Your User Account by sending an e-mail to registered email as the case may be, 
               and Your registration with the Company shall be complete only after such verification. For 
               providing the Services, You authorize the Company to import Your details and personal information.</p>
            <p>You agree that the information You provide to the Company is true, accurate, current, and 
                complete. You also agree that You will ensure that this information is kept accurate and 
                up-to-date at all times. </p>  
            <p>You shall be responsible for maintaining the confidentiality of Your User Accounts and passwords 
                and You agree to accept responsibility for all activities that occur under Your User Account or 
                password and of the Services in Your name. We reserve the right to refuse registration of, or 
                cancel User Accounts which we deem inappropriate. </p> <br>    
            <h2>Windmill services detail</h2>
            <p>The Website specializes in ease of public cloud cost optimization while schedule cloud resources 
               and application infra setup for hosted and kubernetes deployment, while the User avails the Services
               as provided through the Website. </p>
            <p>For the Users, the Website is provided on a subscription basis, wherein the respective business 
               entities or individuals may purchase the subscription plan based on their requirements to manages
               their applications/activities. The Subscription plan shall mean and include the various subscriptions
               plans as made available on the Website. The Users agrees and understand that, initially the Services 
               are made available on a trial basis for an initial period as may be determined by the Company at its 
               sole discretion, thereafter the Users may choose to continue to avail the Services, by opting for a 
               paid subscription plan after the expiry of the trial period amongst the various packages of the subscription
               plans available on the Website.  The User can choose the packages as per their requirement. </p>
            <p>The Company endeavours to take reasonable efforts to make the Services available to You at all 
               the times through the Website. However, as the Services are provided over the Internet, data and 
               cellular networks, the quality and availability of the same may be affected by factors outside the 
               Company’s reasonable control. Therefore, we shall not be liable for non-availability of the Services
               at any time. We shall take reasonable efforts to restore access to the Website and the Services. You 
               waive all Your rights in this regard and confirm that the Company shall not be held liable for any 
               non-performance or availability of the Services, arising due to these limitations or restrictions.</p>
            <p>The website services/products ask you to share public cloud credential, which we will store as 
               per public cloud third party partner guideline. These are required to managed and deployed 
               resource public cloud. The “Company” will signed NDA with customer to use credential for customer 
               application management ONLY. </p>   <br> 
            <h2>Restriction of Windmill services</h2> 
            <p>You understand and acknowledge the following:</p> 
            <p>You will not use the Services for any purpose that is illegal, unlawful or prohibited by these Terms of Service;</p>
            <p>You will not use Services in any manner that threatens or is likely to threaten the integrity, performance or availability of the Services and the Website;</p>
            <p>You will not reverse engineer, decompile or otherwise extract the source code(s) related to the Platform or any part thereof;</p>
            <p>Impersonate another person or access another User’s Account;</p>
            <p>You will not collect information about other Users in any illegal or unlawful manner for any illegal or unlawful purpose;</p>
            <p>You will not use Services in any manner that can damage, disable, overburden, or impair, or undertake any action which is harmful or potentially harmful to, any of the servers, networks, computer systems or resources connected to any of the servers connected, directly or indirectly to the Services or the Platform, or interfere with any other third party’s access to and enjoyment of Services or the Platform.</p> <br>
            <h2>Windmill services Terms</h2>
            <p>Company reserves the right at any time and from time to time to modify or discontinue, temporarily or 
               permanently, the Services, the Platform or the Website (or any part thereof) with or without notice. 
               Further, the Company reserves the right to change these Terms of Service at any time and to notify You 
               by posting an updated version of the Terms of Service on this Website. You are responsible for regularly 
               reviewing these Terms of Service. Continued use of the Services after any such change shall constitute 
               Your consent to be bound by the updated Terms of Service. Your only right with respect to any 
               dissatisfaction with these Terms of Service; any policy or practice of ours in operating the Website or 
               providing the Services, is to stop availing the Services. </p> <br>
            <h2>Limitation of Liability</h2>
            <p>IN NO EVENT SHALL COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE FOR DIRECT, INDIRECT, 
               INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY 
               OF SUCH DAMAGES), RESULTING FROM ANY ASPECT OF YOUR USE OF THE PLATFORM, THE SERVICE, OR THE WEBSITE INCLUDING 
               WITHOUT LIMITATION WHETHER THE DAMAGES ARISE FROM USE OR MISUSE OF THE WEBSITE OR THE SERVICE, FROM INABILITY 
               TO USE THE WEBSITE OR THE SERVICE, OR THE INTERRUPTION, SUSPENSION, MODIFICATION, ALTERATION, OR TERMINATION OF 
               THE WEBSITE OR THE SERVICE. SUCH LIMITATION OF LIABILITY SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY 
               REASON OF OTHER SERVICES OR RENDERED THROUGH OR ADVERTISED IN CONNECTION WITH THE WEBSITE OR THE SERVICES OR 
               ANY LINKS ON THE WEBSITE, AS WELL AS BY REASON OF ANY INFORMATION, OPINIONS OR ADVICE RECEIVED THROUGH OR 
               ADVERTISED IN CONNECTION WITH THE WEBSITE OR THE SERVICES. THESE LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT 
               PERMITTED BY LAW. YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE LIABLE FOR USER DETAILS AND 
               CONTENTS OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY USER OR BUSINESS OR THIRD PARTY AND THAT THE 
               RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.</p>
            <p>THE FOREGOING LIMITATIONS OF LIABILITY SHALL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF 
                ANY LIMITED WARRANTY OR REMEDY HEREIN. </p> <br>
            <h2>Ownership Of Intellectual Property</h2>  
            <p>The Website and all the rights including but not limited to intellectual property rights subsisting under or 
                in relation to the Website are owned by the Company and its affiliates, subsidiaries, licensors as the case may 
                be. The Company respects copyright, and we prohibit You from submitting, uploading, posting, or otherwise 
                transmitting any Content or details on the Website that violates another person’s proprietary rights.       
            </p>  
            <p>If You believe that the Website contains elements that infringe any intellectual property rights, please notify Us 
                immediately. If we receive intellectual property right infringement claim notification, and it is felt 
                appropriate, we may remove all such content which is indicated as infringing and/or take any other appropriate 
                action at our discretion.
            </p>
            <p>All materials on this Website, including but not limited to audio, images, photographs, software, 
                text, icons etc. (the “Website Content”), are protected by copyright under the copyright laws. You 
                cannot use the Website Content, except as specified herein.
            </p>
            <p>‘CloudWindmill’ is a registered trademark, wholly owned by CloudWinmill Solutions LLP. There may be proprietary logos, 
                service marks and trademarks found on the Website whether owned/used by us or otherwise. By displaying them on 
                the Website, we are not granting You any license to utilize those proprietary logos, service marks, or 
                trademarks. Any unauthorized use of the Website Content may violate copyright laws, trademark laws, the laws 
                of privacy and publicity, and civil and criminal statutes.
            </p>
        </div>
    </div>
</div>