import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { IamServiceService } from '../iam-service.service';
import { GlobalService } from 'src/app/stores/global.service';
import { DataService } from 'src/app/data.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SubgroupComponent } from './subgroup/subgroup.component';
@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  @ViewChild(SubgroupComponent, { static: false }) public subGroup;

  @ViewChild('deleteUserModal', { static: false }) public deleteUserModal;
  @ViewChild('addEditUserModal', { static: false }) public addEditUserModal;
  // @ViewChild('userForm', { static: false }) public userForm;
  @ViewChild('editAccessPermissionsModal', { static: false }) public editAccessPermissionsModal;
  @ViewChild('deleteUserAlertModal', { static: false }) public deleteUserAlertModal;
  @ViewChild('stickyMenu', { static: false }) menuElement: ElementRef;

  sticky: boolean = false;
  elementPosition: any;

  menuPosition: any;

  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll')

  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  addGroupPopup: boolean = false;

  constructor(
    public data: DataService,
    public iamService: IamServiceService,
    public globalStore: GlobalService,
    private http: HttpClient,
    private route: Router
  ) { }


  dtOptions: DataTables.Settings = {};
  public start = 0;
  public limit = 10;
  public loading: boolean = false;
  public groups: any = [];
  public grlen = 0;
  public alertDate: Date;
  public loaders: any = { role_id: 0, activateLoader: false, editLoader: false };
  public deleteUserOptions = [{ id: 1, title: 'Suspend' }, { id: 2, title: 'Terminate' }];
  public addEditGroup: any = {};
  public selGroup: any = {};

  public removed: any
  public successMessage = "";
  errorMessage = "";
  public roleStruc: any = {
    dataJson: []
  };
  showGuideBox: boolean = false;

  fnGetGroups(): void {
    this.loading = true;
    let payload: any = {};
    payload.start = this.start;
    payload.length = this.limit;
    payload.session_id = this.globalStore.SESSION_ID;
    this.http
      .post<DataTablesResponse>(
        this.data.loginURL + 'getGroups/details',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        this.groups = resp.data;
        if (resp.data[0].CHILDREN) {
          this.showGuideBox = false
          this.grlen = this.groups[0].CHILDREN.length;
          this.iamService.groups = resp.data;
          this.loading = false;
        } else {
          this.showGuideBox = true
        }
      });

  }

  fnAddSubGroupModal(gr: any, pid: any) {
    if (pid != 0) {
      this.selGroup = gr;
      this.addEditGroup.ID = 0;
      this.addEditGroup.PARENT = pid;
      this.addEditGroup.TITLE = "";
    } else {
      this.selGroup = gr;
      this.addEditGroup.ID = gr.DETAILS.ID;
      this.addEditGroup.PARENT = gr.DETAILS.PARENT;
      this.addEditGroup.TITLE = gr.DETAILS.TITLE;
    }
  }

  fnAddSubGroup() {
    this.loading = true;
    let payload: any = {};
    payload.ID = this.addEditGroup.ID;
    payload.TITLE = this.addEditGroup.TITLE;
    payload.PARENT = this.addEditGroup.PARENT;

    this.http
      .post<any>(
        this.data.loginURL + 'addGroup',
        payload, { headers: this.data.headers() }
      ).subscribe(resp => {
        if (this.addEditGroup.ID == 0) {
          if(this.selGroup && this.selGroup.CHILDREN) {
            this.selGroup.CHILDREN.push(resp.MAIN.RESULT.RESPONSE);
          } else {
            if (resp.MAIN.RESULT.RESPONSE_CODE == 500){
              this.errorMessage = resp.MAIN.RESULT.RESPONSE
            } else{
              let g = {
                DETAILS: resp.MAIN.RESULT.RESPONSE,
                CHILDREN: []
              };
              if (this.groups[0].CHILDREN === null) {
                this.groups[0].CHILDREN = [];
              }
              this.groups[0].CHILDREN.push(g);
              this.subGroup.getChildren(this.groups);

              this.successMessage = "Group has been added successfully";
            }
          }
        } else {
          if(resp && resp.MAIN && resp.MAIN.RESULT) {
            this.selGroup.DETAILS = resp.MAIN.RESULT.RESPONSE;
          }
          this.successMessage = "Group has been updated successfully";
        }
        this.loading = false;
        this.iamService.refreshGroup(true);
        setTimeout(() => {
          this.fnCloseAddGroupPopup();
          this.successMessage = ""
          this.errorMessage = ""
        }, 3000);
      }, error => {
        this.loading = false;

        setTimeout(() => {
          this.fnCloseAddGroupPopup();
        }, 3000);
      });
  }

  fnCloseAddEditGroupModal(): void {
    this.selGroup = {};
    this.addEditGroup = {};
    this.successMessage = "";
    $("#addEditGroupModal").hide();
    $("#addEditGroupModal").removeClass('show');
  }

  fnDeleteSubGroup() {

  }

  fnOpenAddGroupPopUp() {
    this.addGroupPopup = true;
    this.addEditGroup.ID = 0;
    this.addEditGroup.PARENT = this.groups[0].DETAILS.ID;
    this.addEditGroup.TITLE = "";
    console.log(JSON.parse(JSON.stringify(this.selGroup)));
  }

  fnCloseAddGroupPopup() {
    this.addGroupPopup = false;
  }


  ngOnInit() {
    this.iamService.getRefreshValue().subscribe((result => {
      if (result) {
        this.fnGetGroups();
      }
    }))
    this.loading = false;
    this.groups.push({CHILDREN: []});
    this.groups[0].CHILDREN = [];
  }

  fnFindGroup(options: any, callback=(err: any = null, data: any = "")=>{}) {
    if(!options.key || !options.val || options.json) { return; }
    if(!options.findIn) { options.findIn = "CHILDREN"; }
    if(Array.isArray(options.json)) {
      options.json.forEach(obj=>{
        options.json = obj;
        this.fnFindGroup(options);
      });
    } else if(Object(options.json)) {
      if(options.json.hasOwnProperty(options.key) && options.json[options.key] === options.val) {
        callback(null, options.json);
        return;
      } else if(options.json.hasOwnProperty(options.findIn)){
        options.json = options.json[options.findIn];
        this.fnFindGroup(options);
      } else {
        callback('Not found!');
        return;
      }
    }
  }

}
export class DataTablesResponse {
  status_code: any;
  data: any;
  session_id: any;
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
