import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { GlobalService } from './stores/global.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'WindMillOptima';
  constructor(
    private router: ActivatedRoute,
    public authService: AuthService,
    public globalStore: GlobalService,
    private location: Location
  ) { }

  fnCloseModal() {
    $("#unauthorizedModal").hide();
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }


  ngOnInit(): void {
    this.globalStore.SESSION_ID = localStorage.getItem('optimatoken');
    this.authService.optimatoken = localStorage.getItem('optimatoken');
    this.globalStore.IS_LOGGED_IN = this.authService.verifyLogin();
    
    //this.globalStore.IS_LOGGED_IN = this.authService.verifyLogin();
    if (this.globalStore.IS_LOGGED_IN){
      localStorage.setItem('isLoggedIn', 'true');
      this.authService.goTo('dashboard'); // -> replace with dashboard
    }
    else {
      localStorage.setItem('isLoggedIn', 'false');
      this.authService.goTo('login')
    }
    
    // this.globalStore.showSpinner = true
    // setTimeout(() => {
    //   this.globalStore.showSpinner = false
    // }, 1500);
  }

  goBack(): void {
    this.location.back();
  }
}
