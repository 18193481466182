import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Location, JsonPipe } from '@angular/common'
import { ProviderService } from '../provider.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/data.service';
import { ResourcesService } from 'src/app/resources-import/resources.service';
@Component({
  selector: 'app-add-provider',
  templateUrl: './add-provider.component.html',
  styleUrls: ['./add-provider.component.scss']
})
export class AddProviderComponent implements OnInit {

  @ViewChild('stickyMenu', { static: false }) menuElement: ElementRef;


  constructor(
    private router: Router,
    private _location: Location,
    public prodService: ProviderService,
    private http: HttpClient,
    private data: DataService,
    public resourceService: ResourcesService,
  ) { }

  sticky: boolean = false;

  providerDetails: any = {}
  azureProdDetails: any = { "AbsInfo": {} };
  accountInto: any
  externalId: any
  initProviderDetail: any
  jsonAccountInto: any
  trustRelatonshipJson: any
  copyMessage: any
  accCopied: boolean = false
  extIdCopied: boolean = false
  payload: any = {}
  testSuccess: boolean = false
  testSuccessText: any
  showTestMessage: boolean = true
  gcpProdDetails: any = { "AbsInfo": {}, "CloudType": "gcp" };
  elementPosition: any;
  notification:any = 24

  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll')

  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }


  fnApiInit() {
    if (this.prodService.providerType != 'azure') {
      this.http.get<any>(this.data.goUrl + 'initProvider', { params: { 'cloud': this.prodService.init } }).subscribe(resp => {
        if (resp.result == 'success') {
          this.accountInto = resp.response.AbsInfo.account_into
          this.externalId = resp.response.AbsInfo.external_id
          this.initProviderDetail = resp.response
          this.fnLoadTrustRelationship()
        }
      })
    }

  }

  iamJson: any = {
    "Version": "2012-10-17",
    "Id": "WindmillOptimaPolicyAccessCustomerResources",
    "Statement": [{
      "Sid": "WindmillOptimaCloudManagement",
      "Action": [
        "autoscaling:Describe*",
        "autoscaling:ResumeProcesses",
        "autoscaling:SuspendProcesses",
        "autoscaling:UpdateAutoScalingGroup",
        "ce:Describe*",
        "ce:Get*",
        "ce:List*",
        "ec2:Describe*",
        "ec2:ModifyInstanceAttribute",
        "ec2:StartInstances",
        "ec2:StopInstances",
        "iam:GetUser",
        "rds:Describe*",
        "rds:ListTagsForResource",
        "rds:ModifyDBInstance",
        "rds:StartDBCluster",
        "rds:StartDBInstance",
        "rds:StopDBCluster",
        "rds:StopDBInstance",
        "savingsplans:Describe*"
      ],
      "Resource": "*",
      "Effect": "Allow"
    },
    {
      "Sid": "WindmillOptimaStartInstanceWithEncryptedBoot",
      "Effect": "Allow",
      "Action": "kms:CreateGrant",
      "Resource": "*"
    },
    {
      "Sid": "WindmillOptimaCloudWatchAccess",
      "Effect": "Allow",
      "Action": [
        "cloudwatch:GetMetricStatistics",
        "cloudwatch:ListMetrics"
      ],
      "Resource": "*",
      "Condition": {
        "Bool": {
          "aws:SecureTransport": "true"
        }
      }
    }
    ]
  }

  fnLoadTrustRelationship() {
    this.trustRelatonshipJson = {
      "Version": "2012-10-17",
      "Statement": [
        {
          "Effect": "Allow",
          "Principal": {
            "AWS": "arn:aws:iam::" + this.accountInto + ":user/windmill"
          },
          "Action": "sts:AssumeRole",
          "Condition": {
            "StringEquals": {
              "sts:ExternalId": "" + this.externalId + ""
            }
          }
        }
      ]
    }
  }

  fnGoBack() {
    this._location.back();
  }


  showTestBtnLoader: boolean = false;


  fnSubmitProvidersDetails(cloudType: any) {
    this.showTestBtnLoader = true;
    let payload: any = {}
    if (cloudType == 'aws') {
      this.initProviderDetail.AbsInfo.arn = this.providerDetails.arn
      this.initProviderDetail.Title = this.providerDetails.title
      delete this.initProviderDetail.Status
      delete this.initProviderDetail.Resources
      //This is for Provider Notifivcation frequency, should be taken from UI as radio button
      //1 - Hourly
      // 24 - Daily
      // 7 - Weekly
      this.initProviderDetail.Notification = this.notification
      this.fnApiCall(this.initProviderDetail)
    }
    if (cloudType == 'azure') {
      this.azureProdDetails.Zone = ""
      this.azureProdDetails.CloudType = 'azure'
      this.azureProdDetails.Notification = this.notification
      this.fnApiCall(this.azureProdDetails)
    }
    if (cloudType == 'gcp') {
      var data = this.gcpProdDetails.json
      var dataArray = data.split('\r\n');
      for (let i in dataArray) {
        dataArray[i] = JSON.parse(dataArray[i])
      }
      delete this.gcpProdDetails.json
      this.gcpProdDetails.AbsInfo = dataArray[0]
      this.gcpProdDetails.Notification = this.notification
      this.fnApiCall(this.gcpProdDetails)
    }
    // this.providerService.fnAddProvider(payload);
  }




  fnApiCall(payload: any) {
    this.payload = payload
    this.testSuccess = false
    this.http.post<any>(this.data.goUrl + 'testProvider', payload).subscribe(resp => {
      this.showTestBtnLoader = false;

      if (resp.result == 'success') {
        this.testSuccess = true
        this.testSuccessText = resp.respText + ". Hit save button to add Provider"
      }
      if (resp.result == 'failure')
        this.testSuccessText = resp.respText
    })
  }

  fnAddProvider() {
    this.testSuccess = false
    this.showTestMessage = false
    this.http.post<any>(this.data.goUrl + 'addProvider?parent='+sessionStorage.getItem('parentgroup'), this.payload).subscribe(resp => {
      if (resp.result == 'success') {
        this.showTestMessage = true
        this.testSuccessText = "Provider added successfully."
        this.prodService.newProvider = resp.ID
        setTimeout(() => {
          this.router.navigate(['providers'])
        }, 3000);
      } else if (resp.result == 'failure') {
        this.showTestMessage = true
        this.testSuccessText = resp.reason
      }
    })
  }

  copyText(val: string, type: string) {
    let selBox = document.createElement('textarea');
    let jsonVal: any
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    if (type == 'acc') {
      this.copyMessage = "Account Number copied!"
      this.accCopied = true
      setTimeout(() => {
        this.copyMessage = ''
        this.accCopied = false
      }, 2500);
    }
    if (type == 'extid') {
      this.copyMessage = "External ID copied!"
      this.extIdCopied = true
      setTimeout(() => {
        this.copyMessage = ''
        this.extIdCopied = false
      }, 2500);
    }
    if (type == 'trustJson') {
      val = this.trustRelatonshipJson

    }
  }


  iamJsonCopy: boolean = false;
  trJsonCopy: boolean = false;

  copyTheText(item, type): void {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (JSON.stringify(item)));
      e.preventDefault();

      if (type == 'iJson') {
        this.iamJsonCopy = true;
        this.copyMessage = "Copied to Clipboard! "
        setTimeout(() => {
          this.copyMessage = '';
          this.iamJsonCopy = false;
        }, 2500);
      }
      else {
        this.trJsonCopy = true;
        this.copyMessage = "Copied to Clipboard! "
        setTimeout(() => {
          this.copyMessage = '';
          this.trJsonCopy = false;
        }, 2500);
      }
    }

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

 fnSelectNotification(notification:any){
 // alert(notification)
  this.notification = notification
}



  ngOnInit() {
    // this.prodService.providerType
    this.fnApiInit()
    //alert(sessionStorage.getItem('parentgroup'))
  }

}
