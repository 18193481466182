import { Injectable, ViewChild } from '@angular/core';
import { GlobalService } from '../stores/global.service';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AngularElementType } from '@syncfusion/ej2-angular-base';
import { HttpHeaderResponse } from 'node_modules/@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private globalStore: GlobalService,
    private router: Router
  ) { }

  optimatoken: any = ""

  verifyLogin(): boolean {
    if (!this.fnIsLoggedIn()) {
      //this.router.navigate(['/login']);
      this.logout();
      return false;
    }
    else if (this.fnIsLoggedIn()) {
      return true;
    }
  }

  public fnIsLoggedIn(): boolean {
    let status = false;

    if (localStorage.getItem('isLoggedIn') == 'true') {
      if (localStorage.getItem('optimatoken') != "" || localStorage.getItem('optimatoken') != null) {
        this.optimatoken = localStorage.getItem('optimatoken');
        status = true;
      }
    }
    else {
      status = false;
    }
    this.globalStore.IS_LOGGED_IN = status;
    return status;
  }

  logout(): void {
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.setItem('token', '');
    localStorage.setItem('SESSION_ID', '');
    localStorage.removeItem('optimatoken');

    sessionStorage.setItem('createdby', '');
    sessionStorage.setItem('name', '');
    sessionStorage.setItem('userId', '');
    sessionStorage.setItem('mobile', '');
    sessionStorage.setItem('createdby', '');
    sessionStorage.setItem('client', '');
    sessionStorage.setItem('super_admin', '');
    this.globalStore.IS_LOGGED_IN = false;
    this.router.navigate(['/login']);
  }

  goTo(location): void {
    this.router.navigate([location]);
  }
}
export class DataTablesResponse {
  status_code: any;
  data: any;
  session_id: any;
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  MAIN: any;
  DATA:any;
}

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

  @ViewChild('unauthorizedModal', { static: false }) public unauthorizedModal;

  

  constructor(private authService: AuthService, public route: Router,public globalStore: GlobalService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // add authorization header with basic auth credentials if available
    if (this.authService.optimatoken != "") {
      var request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.optimatoken}`
        }
      });
    }
    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
           //alert(event.status)
          }
          return event;
        }),

        catchError((error: HttpErrorResponse) => {

          //alert(error.status)
          //alert(error.statusText)
          if (error.status === 401 || error.status === 404) {

            if (error.url.indexOf("9090") >-1  || error.url.indexOf("optima") >-1){
              this.globalStore.URL = "Request is not authorized by optima server"
            }
            if (error.url.indexOf("8081") >-1  || error.url.indexOf("iam") >-1){
              this.globalStore.URL = "Request is not authorized by iam server"
            }
            $("#unauthorizedModal").show();
            $("#unauthorizedModal").addClass('show');
            $('body').addClass('modal-open');
            this.authService.logout()
          }
          if (error.status === 403) {
            if (error.url.indexOf("9090") >-1  || error.url.indexOf("optima") >-1){
              this.globalStore.URL = "Request is forbidden by optima server"
            }
            if (error.url.indexOf("8081") >-1  || error.url.indexOf("iam") >-1){
              this.globalStore.URL = "Request is forbidden by iam server"
            }
            //alert("Error is received with code  403")
            $("#unauthorizedModal").show();
            $("#unauthorizedModal").addClass('show');
            $('body').addClass('modal-open');
          }
          //alert(error.url)
          if (error.url.indexOf("login") >-1 ){
            this.globalStore.URL = "IAM server is shutdown...."
            //alert(this.globalStore.URL)
            $("#unauthorizedModal").show();
            $("#unauthorizedModal").addClass('show');
            $('body').addClass('modal-open');
            this.authService.logout()
          }
          if (error.url.indexOf("9090") >-1  || error.url.indexOf("/optima/") >-1){
            this.globalStore.URL = "Optima server is shutdown...."
            //alert(this.globalStore.URL)
            $("#unauthorizedModal").show();
            $("#unauthorizedModal").addClass('show');
            $('body').addClass('modal-open');
            this.authService.logout()
          }
      

          return throwError(error);
        })
      );

  }
  //return next.handle(request);
}