import { Component, OnInit } from '@angular/core';
import { DataService, fadeIn } from '../data.service';
import { HttpClient } from '@angular/common/http';
import { trigger } from '@angular/animations';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    trigger('fadeIn', fadeIn())
  ]
})
export class ProfileComponent implements OnInit {

  constructor(
    private data: DataService,
    private http: HttpClient
  ) { }

  profile: any = [];
  name: any
  mobile: any
  userId: any
  resourcesCount: any
  cost: any
  providersCount: any
  oldNewPasswordMatch: boolean = false

  dataLoaded: boolean = false;
  resetPassword: any = {}
  message: any
  client:any

  fnGetProfile() {
    this.resourcesCount = this.data.totalVM
    this.cost = this.data.vmCost
    this.providersCount = this.data.totalProvider
    this.http.post<any>(this.data.loginURL + 'currentUser', null).subscribe(
      resp => {
        this.profile = resp.data[0].NAME;
        this.userId = resp.data[0].USERID;
        this.mobile = resp.data[0].MOBILE;
        this.client = resp.data[1].Organization;

        // setTimeout(() => {
        this.dataLoaded = true;


        // }, 2500)
      }
    )
  }

  fnResetPassword() {
    // console.log(this.resetPassword)
    // if (this.resetPassword.currentPassword == this.resetPassword.newPassword) {
    //   this.samePasswordError = "New password cannot be same as Current Password"
    //   setTimeout(() => {
    //     this.samePasswordError = ""
    //   }, 1500);
    // }
    this.http.post<any>(this.data.loginURL + 'resetPassword', this.resetPassword).subscribe(resp => {
      // console.log(resp);
      if (resp.status_code == 200) {
        this.message = resp.data.status
        $("#passwordMessage").removeClass('text-danger')
        $("#passwordMessage").addClass('text-success')
        setTimeout(() => {
          this.message = ""
        }, 3000);
      } else if (resp.status_code == 500) {
        this.message = resp.data.status
        $("#passwordMessage").removeClass('text-success')
        $("#passwordMessage").addClass('text-danger')
        setTimeout(() => {
          this.message = ""
        }, 3000);
      }
    });
  }

  fnCompare() {
    if (this.resetPassword.currentPassword == this.resetPassword.newPassword)
      this.oldNewPasswordMatch = false
    else
      this.oldNewPasswordMatch = true
  }

  ngOnInit() {
    this.fnGetProfile()
    this.fnGetRole(sessionStorage.getItem('role_id'), '0');
    this.rolename = sessionStorage.getItem('role_name')

  }


  selectRole: any = [];
  disableUpdate: boolean = true;
  roleList: any = []
  rolename:any
  fnChangeAccessPermission(index: any, scope: any, type: any, ap: any) {
    this.disableUpdate = false;
    for (let i in this.selectRole) {
      if (scope == this.selectRole[i].scope) {
        if (type == 'add') {
          if (ap == 1) {
            this.selectRole[i].permissions[index].permission.add = 0
          } else {
            this.selectRole[i].permissions[index].permission.add = 1
          }
        } else if (type == 'edit') {
          if (ap == 1) {
            this.selectRole[i].permissions[index].permission.edit = 0
          } else {
            this.selectRole[i].permissions[index].permission.edit = 1
          }
        } else if (type == 'delete') {
          if (ap == 1) {
            this.selectRole[i].permissions[index].permission.delete = 0
          } else {
            this.selectRole[i].permissions[index].permission.delete = 1
          }
        } else {
          if (ap == 1) {
            this.selectRole[i].permissions[index].permission.view = 0
          } else {
            this.selectRole[i].permissions[index].permission.view = 1
          }
        }
      }
    }
  }

  async fnGetRole(id: any, index: any) {
    this.disableUpdate = true

    let payload: any = {}
    if (id == '') {
      payload.role_id = '0'
    } else {
      payload.role_id = id
    }
    await this.http.post<DataTablesResponse>(this.data.loginURL + 'roles', payload, { headers: this.data.headers() }).subscribe(resp => {
      if (this.roleList.length == 0) {
        this.roleList = resp.data;
      }
      if (resp.data.length == undefined) {
        this.selectRole = []
        this.selectRole = resp.data.ACCESS_PERMISSION
      }
    });

  }

}

export class DataTablesResponse {
  data: any;
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
