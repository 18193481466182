<div class="below-navbar">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h3 class="below-nav-heading">
          Roles
        </h3>
      </div>
    </div>
  </div>
</div>

<section class="mt-3">
  <div class="container">
    <div class="box">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="box">
                <div class="border-bottom">
                  <h4>
                    All Roles
                  </h4>
                  <!-- <div class="right-nav">
                    <button type="button"  class="btn  btn-wide btn-create-green  create-surveyor pos-rel" *ngIf="viewOnly"
                      (click)="fnGetAccess();fnShowHideAddPermission(); fnAddNewRoleTitle()">
                      <i class="fa fa-plus"></i>
                      Add
                    </button>
                    <button type="button"  class="btn  btn-wide btn-create-green  create-surveyor pos-rel" *ngIf="!viewOnly"
                      (click)="fnShowHideAddPermission(); fnRemoveRoleTitle()">
                      <i class="fa fa-times"></i>
                      Cancel
                    </button>
                  </div> -->
                </div>
                <ul class="q-list">
                  <li class="new-question" *ngFor="let rl of roleList; let i = index"
                    [ngClass]="{'active': i == selectedRoleIndex}" (click)="fnGetRole(rl.ID, i)">
                    <a>
                      {{rl.ROLE}}
                    </a>
                    <!-- <a *ngIf="!rl.ROLE">
                      {{newRoleTitle}}
                    </a> -->
                  </li>
 
                  <li class="new-question" *ngFor="let rl of newRoleAdded; let i = index"
                    [ngClass]="{'active': selectedRoleIndex == 'new'}">
                    <a>
                      <span *ngIf="newRoleTitle">
                        {{newRoleTitle}}
                      </span>
                      <span *ngIf="!newRoleTitle">
                        New Role
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-9 col-sm-12">
              <div class="box clearfix" style="margin-left: -15px; margin-right: -15px; border-bottom: none">
                <ul class="unstyled details-tab text-center">
                  <li class="active">
                    <a>
                      Permissions
                    </a>
                  </li>
                </ul>
                <div class="col-md-12" *ngIf="viewOnly">
                  <!-- <div class="text-right">
                    <button class="btn btn-default mb-4" style="margin-right: -15px;" (click)="fnUpdate()"
                      [disabled]="disableUpdate">
                      Update
                    </button>
                  </div> -->
                  <div class="row permission-box mb-3" *ngFor="let sr of selectRole">
                    <div class="col-md-3 col-sm-12 scope">
                      <p>
                        {{sr.scope}}
                      </p>
                    </div>
                    <div class="col-md-9 col-sm-12">
                      <div *ngFor="let prmsn of sr.permissions; let i = index">
                        <div class="row mb-2">
                          <div class="col-md-3 col-sm-12">
                            <span style="font-size: 14px; text-transform: capitalize;">
                              {{prmsn.type}}
                            </span>
                          </div>
                          <div class="col-md-9 col-sm-12">
                            <div class="d-inline-block w-25" >

                              <div class="granted-permission" 
                                (click)="disableUpdate ?null:fnChangeAccessPermission(i, sr.scope,'add',prmsn.permission.add)"
                                [ngClass]="{'1':'color-green','0':'color-red'}[prmsn.permission.add]"
                                 >
                                Add
                                <i class="fa" [ngClass]="{'1':'fa-check','0':'fa-times'}[prmsn.permission.add]" ></i>
                              </div>

                            </div>
                            <div class="d-inline-block w-25"
                              (click)="disableUpdate ?null:fnChangeAccessPermission(i, sr.scope,'edit', prmsn.permission.edit)"
                              [ngClass]="{'1':'color-green','0':'color-red'}[prmsn.permission.edit]"
                              >
                              <div class="granted-permission">
                                Edit
                                <i class="fa" [ngClass]="{'1':'fa-check','0':'fa-times'}[prmsn.permission.edit]"></i>
                              </div>
                            </div>
                            <div class="d-inline-block w-25"
                              (click)="disableUpdate ?null:fnChangeAccessPermission(i, sr.scope,'delete', prmsn.permission.delete)"
                              [ngClass]="{'1':'color-green','0':'color-red'}[prmsn.permission.delete]"
                              >
                              <div class="granted-permission">
                                Delete
                                <i class="fa" [ngClass]="{'1':'fa-check','0':'fa-times'}[prmsn.permission.delete]"></i>
                              </div>
                            </div>
                            <div class="d-inline-block w-25"
                              (click)="disableUpdate ?null:fnChangeAccessPermission(i, sr.scope,'view', prmsn.permission.view)"
                              [ngClass]="{'1':'color-green','0':'color-red'}[prmsn.permission.view]"
                              >
                              <div class="granted-permission">
                                View
                                <i class="fa" [ngClass]="{'1':'fa-check','0':'fa-times'}[prmsn.permission.view]"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12" *ngIf="!viewOnly">
                  <form (submit)="fnSaveRole()">
                    <div class="form-group" style="margin-left: -15px; margin-right: -15px;">
                      <label for="title">
                        Title
                      </label>
                      <input type="text" class="form-control" name="title" [(ngModel)]="newRoleTitle"
                        placeholder="Role Title">
                    </div>
                    <div class="row permission-box mb-3" *ngFor="let ap of accessPermission">
                      <div class="col-md-3 col-sm-12 scope">
                        <p>
                          {{ap.scope}}
                        </p>
                      </div>
                      <div class="col-md-9 col-sm-12">
                        <div *ngFor="let prmsn of ap.permissions; let i = index">
                          <div class="row mb-2">
                            <div class="col-md-3 col-sm-12">
                              <span style="font-size: 14px; text-transform: capitalize;">
                                {{prmsn.type}}
                              </span>
                            </div>
                            <div class="col-md-9 col-sm-12">
                              <div class="d-inline-block w-25">
                                <div class="granted-permission-checkbox">
                                  <div class="form-group ">
                                    <div class="checkbox">
                                      <label class="form-check-label" for="add{{prmsn.type}}">
                                        <input type="checkbox" id="add{{prmsn.type}}" class="form-check-input"
                                          [ngModelOptions]="{standalone: true}" [(ngModel)]="prmsn.permission.add"
                                          name="add">
                                        <span class="label-text">
                                          Add
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="d-inline-block w-25">
                                <div class="granted-permission-checkbox">
                                  <div class="form-group ">
                                    <div class="checkbox">
                                      <label class="form-check-label" for="edit{{prmsn.type}}">
                                        <input type="checkbox" id="edit{{prmsn.type}}" class="form-check-input"
                                          [ngModelOptions]="{standalone: true}" [(ngModel)]="prmsn.permission.edit"
                                          name="edit">
                                        <span class="label-text">
                                          Edit
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="d-inline-block w-25">
                                <div class="granted-permission-checkbox">
                                  <div class="form-group ">
                                    <div class="checkbox">
                                      <label class="form-check-label" for="delete{{prmsn.type}}">
                                        <input type="checkbox" id="delete{{prmsn.type}}" class="form-check-input"
                                          [ngModelOptions]="{standalone: true}" [(ngModel)]="prmsn.permission.delete"
                                          name="delete">
                                        <span class="label-text">
                                          Delete
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="d-inline-block w-25">
                                <div class="granted-permission-checkbox">
                                  <div class="form-group ">
                                    <div class="checkbox">
                                      <label class="form-check-label" for="view{{prmsn.type}}">
                                        <input type="checkbox" id="view{{prmsn.type}}" class="form-check-input"
                                          [ngModelOptions]="{standalone: true}" [(ngModel)]="prmsn.permission.view"
                                          name="view">
                                        <span class="label-text">
                                          View
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <button class="btn  btn-wide btn-create-green  create-surveyor pos-rel" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
