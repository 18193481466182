<div class="below-navbar ">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h3 class="below-nav-heading">
          Select Provider
        </h3>
      </div>
    </div>
  </div>
</div>
<div class="middle-bar animated fadeInDown" [class.sticky]="sticky" #stickyMenu>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-5 col-sm-12">
            <button type="button" style="font-weight: 300; height: 40px;" class="btn btn-success-blue btn-create-green"
              (click)="fnGoBack()">
              <span>
                <i class="fa fa-backward mr-2"></i>
              </span>
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="mt-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3 col-sm-12" *ngFor="let provider of providersImages">
            <div class="box text-center">
              <button class="btn btn-image" *ngIf="provider.disabled === 1"
                (click)="goToAddProviders(provider.providerType, provider.init)">
                <img [src]="provider.src" alt="" width="225px" height="168px">
              </button>
              <button class="btn btn-image" disabled *ngIf="provider.disabled === 0">
                <img [src]="provider.src" alt="" width="225px" height="168px">
              </button>
            </div>
            <p class="text-center mb-0 text-danger" *ngIf="permissionmsg !== ''">
              {{permissionmsg}}
          </div>
          <div class="col-md-3 col-sm-12" [hidden]="simulatorDisable" >
            <div class="box text-center position-relative">
              <span 
                style="font-size: 28px;font-weight: bold;position: absolute;top: 9%;left: 50%;transform: translate(-50%, -50%);">
                Simulator
              </span>
              <button class="btn btn-image" data-toggle="modal" data-target="#simulatorModal">
                <img src="./assets/images/aws.png" alt="" (click)="fnOpenModal('aws')" width="225px" height="168px">
              </button>
            </div>
          </div>
          <div class="col-md-3 col-sm-12" [hidden]="simulatorDisable">
            <div class="box text-center position-relative">
              <span
                style="font-size: 28px;font-weight: bold;position: absolute;top: 9%;left: 50%;transform: translate(-50%, -50%);">
                Simulator
              </span>
              <button class="btn btn-image" data-toggle="modal" data-target="#simulatorModal">
                <img src="./assets/images/azure.png" alt="" (click)="fnOpenModal('azure')" width="225px" height="168px">
              </button>
            </div>
          </div>
          <div class="col-md-3 col-sm-12" [hidden]="simulatorDisable">
            <div class="box text-center position-relative">
              <span
                style="font-size: 28px;font-weight: bold;position: absolute;top: 9%;left: 50%;transform: translate(-50%, -50%);">
                Simulator
              </span>
              <button class="btn btn-image" data-toggle="modal" data-target="#simulatorModal">
                <img src="./assets/images/gcp.png" alt="" (click)="fnOpenModal('gcp')" width="225px" height="168px">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--==================== Simulator Provider Modal ====================-->
<div class="modal fade" id="simulatorModal" tabindex="-1" role="dialog" aria-labelledby="simulatorModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="simulatorModalLabel">
          Enter the following details to add simulator
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="(!showLoader && successMessage == '')">
        <form name="myForm">
          <div class="form-group" *ngIf="!hideAddedSimulators">
            <label for="previousProvider">
              Select Previous Provider
            </label>
            <select class="form-control" id="previousProvider" [(ngModel)]="previousProviderSelected"
              name="previousProvider">
              <option value="0" selected>
                Select
              </option>
              <option value="{{rt.ID}}" *ngFor="let rt of addedProvidersList">
                {{rt.Title}}
              </option>
            </select>
          </div>
          <div class="form-group" *ngIf="!hidePathTextBox">
            <label for="selectPath">
              Enter Title
            </label>
            <input type="text" id="selectPath" name="selectPath" class="form-control" [(ngModel)]="providerPath">
          </div>
          <div *ngIf="azure" class="form-group">
            <label for="resourceGroup">
              Resource Group
            </label>
            <input type="text" class="form-control" id="resourceGroup" [(ngModel)]="resourceGroup" name="resourceGroup">
          </div>
          <div class="form-group">
            <label for="score">
              Score
            </label>
            <input type="text" class="form-control" id="score" [(ngModel)]="score" name="score">
          </div>
          <div class="form-group">
            <label for="instancesCount">
              Number of instances
            </label>
            <input type="text" class="form-control" id="instancesCount" [(ngModel)]="instancesCount"
              name="instancesCount">
          </div>
          <div class="form-group">
            <label for="instancesCount">
              Instance Type
            </label>
            <select class="form-control" id="resourceType" [(ngModel)]="selectedResourceType" name="instancetype">
              <option value="0" selected>
                Select
              </option>
              <option value="{{rt}}" *ngFor="let rt of resourceType">
                {{rt}}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="resourceStatus">
              Instance Status
            </label>
            <select class="form-control" id="resourceStatus" [(ngModel)]="selectedInstanceStatus" name="instancestatus">
              <option value="0">
                Select
              </option>
              <option value="{{rt}}" *ngFor="let rt of instanceStatus">
                {{rt}}
              </option>
            </select>
          </div>
          <div *ngFor="let d of distribution; let i = index">
            {{i+1}}: Type: {{d.instancetype}}, Status: {{d.instancestatus}}, Count: {{d.instances}} <span
              *ngIf="azure">, Resource Group: {{d.resourcegroup}}</span>
          </div>
          <div class="modal-footer text-center">
            <button class="btn btn-success" (click)="fnAddInstances()">Add More</button>
            <button *ngIf="aws" type="button" class="btn btn-success"
              (click)="fnSubmitProvidersDetails('aws')">Submit</button>
            <button *ngIf="azure" type="button" class="btn btn-success"
              (click)="fnSubmitProvidersDetails('azure')">Submit</button>
            <button *ngIf="gcp" type="button" class="btn btn-success"
              (click)="fnSubmitProvidersDetails('gcp')">Submit</button>
          </div>
        </form>
      </div>
      <!--=========== Show this body after button click ===========-->
      <div class="modal-body pt-0" *ngIf="showLoader">
        <div class="custom-loader">
          Please Wait
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>
      <!--=========== Show this body after API response is success ===========-->
      <div class="modal-body pt-0" *ngIf="(!showLoader && successMessage != '')">
        <div class="success-message">
          <p class="d-inline-block">
            {{successMessage}}
          </p>
          <svg *ngIf="success" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
